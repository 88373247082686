import React, { useState, useContext, useEffect } from "react"
import PropTypes from "prop-types"

import { getRequest } from "../../../../services/rest_service"
import { ClassroomContext } from "../../../../stores/classroom_store/ClassroomStore"
import {
  SET_ACTIVE_BREAKOUT_SESSION,
  SET_SHOW_BREAKOUT_SESSION_EDIT_DURATION_MODAL,
  SET_OPENED_OVERLAY_SECTION,
  SET_SHOW_BREAKOUT_SESSION_EXTEND_DURATION_MODAL,
} from "../../../../stores/classroom_store/ClassroomActions"
import Image from "../../../custom/Image"
import HorizontalScroll from "../../../custom/HorizontalScroll"
import Btn from "../../../custom/Button"
import WarningModal from "../../../custom/WarningModal"
import WarningIcon from "../../../../images/svgs/warning_icon.svg"
import BreakoutSessionIcon from "../../../../images/svgs/lesson_board_breakout_session_icon.svg"

import Style from "../style.module.scss"
import { GlobalContext } from "../../../../stores/global_store/GlobalStore"
import { SET_NOTIFICATION_DATA } from "../../../../stores/global_store/GlobalActions"

/**
 * Component for Breakout Session box
 * @param {object} props.session - session object
 * @param {string} props.title - module title
 * @returns {React.ReactElement} Breakout Session box in lesson board topic card
 */

const BreakoutSessionBox = ({ session, title }) => {
  const [classroomState, dispatch] = useContext(ClassroomContext)
  const [globalStore, dispatchGlobalStore] = useContext(GlobalContext)

  const [ignoreGroupMural, setIgnoreGroupMural] = useState(false)
  const [warningModal, setWarningModal] = useState({})

  useEffect(() => {
    if (ignoreGroupMural) {
      setIgnoreGroupMural(false)
      handleBreakoutSessionStart(true)
    }
  }, [ignoreGroupMural])

  const handleBreakoutSessionStart = (show_edit_time_modal) => {
    if (classroomState.breakoutGroupsIds.length > 0) {
      dispatch({ type: SET_ACTIVE_BREAKOUT_SESSION, payload: session })
      dispatch({ type: SET_OPENED_OVERLAY_SECTION, payload: "ActiveBreakoutSession" })
      if (show_edit_time_modal) {
        dispatch({ type: SET_SHOW_BREAKOUT_SESSION_EDIT_DURATION_MODAL, payload: true })
      }
    } else {
      dispatchGlobalStore({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: "No breakout groups" } })
    }
  }

  const inconsistentMuralsInGroups = async () => {
    let response = await getRequest(`/breakoutgroups/?training=${classroomState.training_id}`)
    if (response.success) {
      let groups = response.data.results
      let link_exists = 0
      groups.map((group) => {
        if (group.workspace_link) {
          link_exists += 1
        }
      })
      if (!(link_exists === 0 || link_exists === groups.length)) {
        return true
      }
    }
    return false
  }

  return (
    <div className={`${Style.breakout_session_box} p-2 mt-2`}>
      <div className="d-flex align-items-center">
        <Image src={BreakoutSessionIcon} alt={`breakout_session_icon`} className={`my-auto`} />
        <p>{session.name}</p>
      </div>
      {title === "Doing" ? (
        <div className={`d-inline-flex pt-2 w-100 align-items-center scroll-x`}>
          <HorizontalScroll className={`d-inline-flex w-100 align-items-center`} scrollId={session.id}>
            <Btn
              type="small"
              onClick={async () => {
                if (session.status === "0") {
                  let inconsistentMurals = await inconsistentMuralsInGroups()
                  if (inconsistentMurals) {
                    setWarningModal({ show: true })
                  } else handleBreakoutSessionStart(true)
                } else handleBreakoutSessionStart(false)
              }}
              className={`d-flex ml-0`}
            >
              {session?.status === "1" ? "View Session" : session?.status === "0" ? "Start Session" : "View Results"}
            </Btn>
            {session?.status === "1" ? (
              <Btn
                onClick={(e) => {
                  dispatch({ type: SET_SHOW_BREAKOUT_SESSION_EXTEND_DURATION_MODAL, payload: true })
                }}
                className={`d-flex ml-2`}
                variant="none"
              >
                Extend time
              </Btn>
            ) : null}
            {session?.status === "2" ? (
              <Btn onClick={() => handleBreakoutSessionStart(true)} className={`d-flex ml-2`}>
                Re-Launch
              </Btn>
            ) : null}
          </HorizontalScroll>
        </div>
      ) : null}
      <WarningModal
        show={warningModal}
        onHide={() => {
          setWarningModal({})
          localStorage.setItem("inconsistent-murals", true)
          dispatch({ type: SET_OPENED_OVERLAY_SECTION, payload: "BreakoutGroup" })
        }}
        action={() => {
          setIgnoreGroupMural(true)
          setWarningModal({})
        }}
        okayBtnName={"Yes"}
        cancelBtnName={"No"}
        loader={false}
        icon={WarningIcon}
      >
        <div className={`d-flex flex-column`}>
          <p className="mb-1">Few breakout rooms don’t have the mural links.</p>
          <p className="mb-0">Do you want to start a new breakout Session ?</p>
        </div>
      </WarningModal>
    </div>
  )
}

BreakoutSessionBox.propTypes = {
  session: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
}

export default BreakoutSessionBox
