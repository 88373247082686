import React, { useContext, useEffect, useState } from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"

import { Col } from "react-bootstrap"
import { DragDropContext } from "react-beautiful-dnd"

import {
  SET_TRAINING_ID,
  SET_TEMPLATE_ID,
  SET_COMMON_RESOURCE_DRAG_ENDED,
  SET_SHARED_RESOURCE_DRAG_ENDED,
  SET_BREAKOUT_RESOURCE_DRAG_ENDED,
  SET_ACTIVE_ACTIVITY_DATA,
  SET_TRAINING_BREAK,
  SET_ACTIVE_BREAKOUT_SESSION,
  SET_OPENED_OVERLAY_SECTION,
} from "../../../stores/classroom_store/ClassroomActions"
import { ClassroomContext } from "../../../stores/classroom_store/ClassroomStore"
import { TrainingSocketContext } from "../../../stores/socket/training_socket/trainingSocketProvider"
import { SET_DRAGGED_ITEM } from "../../../stores/classroom_store/classroom_sidebar/classroomSidebarActions"
import { ClassroomSidebarContext } from "../../../stores/classroom_store/classroom_sidebar/classroomSidebarStore"
import { getRequest } from "../../../services/rest_service"
import Loader from "../../custom/Loader"
import Header from "../header/header"
import SessionBoard from "../session_overview/sessionBoard"
import LessonBoard from "../lesson_board/lessonBoard"
import TriggeredResource from "../../resource/triggerResource"
import ActivityWarning from "../../class-room-training/classroom-components/activity-warning"
import ActiveBreakoutSession from "../breakout_session/active_breakout_session"
import ClassroomSidebar from "../classroom_sidebar/classroomSidebar"
import { LessonboardContext } from "../../../stores/classroom_store/lessonboard_store/LessonboardStore"
import { SET_MODULES_DATA } from "../../../stores/classroom_store/lessonboard_store/LessonboardActions"
import { QuestionContext } from "../../../stores/question_store/QuestionStore"

import ClassroomStyle from "./classroom.module.scss"
import BreakoutGroups from "../../template-resources/breakout-groups/breakoutGroups"
import SessionBreak from "../../template-resources/session-break/session-break"
import LiveDiscussionBoard from "../../live-discussion-board/live-discussion-board"
import LiveDiscussion from "../../live-discussion-board/live-discussion"
import BreakoutSession from "../../template-resources/BreakoutSession"
import QuestionSingle from "../../question-answer/QuestionSingle"
import QuestionsList from "../../question-answer/QuestionsList"
import SessionBurnDown from "../../insights/SessionBurnDown"
import ZoomTest from "../../video-conference/zoom-test"
import Encryption from "../../../services/encryption"

/**
 * Component for live class.
 * @param {number} trainingId - Training ID
 * @param {string} templateId - Template ID
 * @returns {React.ReactElement} Classroom component.
 */

const Classroom = ({ trainingId, templateId }) => {
  const [classroomState, dispatch] = useContext(ClassroomContext)
  const [sidebarState, sidebarDispatch] = useContext(ClassroomSidebarContext)
  const [lessonboardStore, lessonboardDispatch] = useContext(LessonboardContext)
  const [questionStore, questionDispatch] = useContext(QuestionContext)
  const [isSocketReady, recvSocketData, sendSocket] = useContext(TrainingSocketContext)
  const [trainingDetail, setTrainingDetail] = useState("")
  const [trainingDetailFetched, setTrainingDetailFetched] = useState(false)
  const [lessonBoardModules, setLessonBoardModules] = useState([])
  const [participantData, setParticipantData] = useState(null)
  const [hideZoom, setHideZoom] = useState(false)
  const encryption = new Encryption(process.env.GATSBY_REACT_APP_ENCRYPTION_PASSWORD, process.env.GATSBY_REACT_APP_ENCRYPTION_KEY)

  useEffect(() => {
    fetchTrainingDetail()
    fetchLessonBoardModules()
    dispatch({ type: SET_TRAINING_ID, payload: trainingId })
  }, [])

  useEffect(() => {
    if (isSocketReady && recvSocketData && Object.keys(recvSocketData).length > 0 && recvSocketData.event_type === "class_ended") {
      let training_detail = { ...trainingDetail }
      training_detail.class_status = "2"
      setTrainingDetail(training_detail)
      redirectToDashboard()
    }
    if (recvSocketData.event_type === "training_break" && recvSocketData.action === "started") {
      dispatch({ type: SET_ACTIVE_ACTIVITY_DATA, payload: null })
      dispatch({ type: SET_TRAINING_BREAK, payload: recvSocketData.payload.break_info })
    }
  }, [isSocketReady, recvSocketData])

  useEffect(() => {
    if (recvSocketData?.event_type === "breakout_session_status") {
      dispatch({
        type: SET_ACTIVE_BREAKOUT_SESSION,
        payload: { ...recvSocketData?.data },
      })
      recvSocketData?.data?.status == 1 && dispatch({ type: SET_OPENED_OVERLAY_SECTION, payload: "ActiveBreakoutSession" })
    }
  }, [recvSocketData])

  async function fetchTrainingDetail() {
    let response = await getRequest(`/my-training-desc/${trainingId}/`)
    if (response.success) {
      let training_detail = response.data[0]
      setTrainingDetail(training_detail)
      if (training_detail.class_status !== "1") {
        redirectToDashboard()
      }
      dispatch({ type: SET_TEMPLATE_ID, payload: training_detail.default_course_template_id })
      setTrainingDetailFetched(true)
    } else {
      // TODO: add global error handling
    }
  }

  const fetchLessonBoardModules = async () => {
    let response = await getRequest(`/lessonboard-stage/?template=${templateId}`)
    if (response.success) {
      setLessonBoardModules(response.data.results)
      lessonboardDispatch({ type: SET_MODULES_DATA, payload: response.data.results })
    } else {
      // TODO: add global error handling
    }
  }
  function onDragEnd(result) {
    if (result) {
      if (classroomState.openedOverlaySection === "BreakoutGroup") {
        dispatch({ type: SET_COMMON_RESOURCE_DRAG_ENDED, payload: true })
      } else if (classroomState.openedOverlaySection === "ActiveBreakoutSession") {
        dispatch({ type: SET_SHARED_RESOURCE_DRAG_ENDED, payload: true })
      } else if (classroomState.openedOverlaySection === "BreakoutSession") {
        dispatch({ type: SET_BREAKOUT_RESOURCE_DRAG_ENDED, payload: true })
      }
      sidebarDispatch({ type: SET_DRAGGED_ITEM, payload: result })
    } else {
      return null
    }
  }

  const redirectToDashboard = () => {
    setTimeout(() => {
      navigate(`/main/class-dashboard/${templateId}/${trainingId}/`)
    }, 5000)
  }

  function handleZoomHide() {
    setHideZoom(true)
  }
  let start_url = ""
  let meeting_id = ""
  let password = ""
  if (trainingDetail?.training_zoom_link?.meeting_id) {
    start_url = encryption.decryption(trainingDetail?.training_zoom_link?.start_url)
    meeting_id = encryption.decryption(trainingDetail?.training_zoom_link?.meeting_id)
    password = encryption.decryption(trainingDetail?.training_zoom_link?.password)
    console.log(start_url, "start_url")
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Col xs="12" sm="12" md="12" lg="12" className={`white-text ${ClassroomStyle.classroom_main_div}`}>
        <ClassroomSidebar parentComponent="classroom" setParticipantData={setParticipantData} participantData={participantData} />
        {trainingDetailFetched ? (
          trainingDetail.class_status === "1" ? (
            <div className={`d-flex flex-column`} style={{ height: "100vh" }}>
              <Header trainingDetail={trainingDetail} />
              {classroomState.activeSection === "LessonBoard" && (
                <>
                  <SessionBoard />
                  {trainingDetail?.training_zoom_link && !hideZoom && (
                    <ZoomTest type="small" handleHide={handleZoomHide} detail={{ meeting_id, password, start_url, trainingId }} />
                  )}
                  {lessonboardStore?.modules?.length > 0 && <LessonBoard />}
                  {classroomState.showTriggerResource && <TriggeredResource />}
                  <ActivityWarning />
                  {classroomState.openedOverlaySection === "BreakoutGroup" && <BreakoutGroups />}
                  {classroomState.openedOverlaySection === "BreakoutSession" && <BreakoutSession parent_page="live_class" />}
                  {(sidebarState.currentNavTab === "Break" || classroomState.trainingBreak) && <SessionBreak />}
                  {classroomState.discussionBoard.modal && <LiveDiscussion />}
                  {classroomState.activeBreakoutSession && <ActiveBreakoutSession />}
                  {questionStore.show_question_modal?.type === "question" ? <QuestionSingle /> : null}
                  {questionStore.show_question_modal?.type === "question_list" ? <QuestionsList /> : null}
                </>
              )}
              {classroomState.activeSection === "DiscussionBoard" && classroomState.discussionBoard.active && <LiveDiscussionBoard />}
            </div>
          ) : (
            <div className="text-center py-5">
              <p>This class is not live now.</p>
            </div>
          )
        ) : (
          <div className="d-flex-centered h-100">
            <Loader />
          </div>
        )}
      </Col>
    </DragDropContext>
  )
}

export default Classroom

Classroom.propTypes = {
  trainingId: PropTypes.string.isRequired,
  templateId: PropTypes.string.isRequired,
}
