import React, { useContext } from "react"
import PropTypes from "prop-types"
import Btn from "../../../custom/Button"
import Image from "../../../custom/Image"
import getImageToBeRendered from "../../../utilities/resourceIcons"
import { ClassroomContext } from "../../../../stores/classroom_store/ClassroomStore"
import {
  SET_ACTIVE_ACTIVITY_DATA,
  SHOW_ACTIVE_ACTIVITY,
  SHOW_TRIGGERED_RESOURCE,
  VIEW_MODERATION_RESOURCE,
} from "../../../../stores/classroom_store/ClassroomActions"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faSpinner } from "@fortawesome/free-solid-svg-icons"

import Style from "../style.module.scss"
import { getRequest, putRequest } from "../../../../services/rest_service"
import { SET_RESOURCE_DATA } from "../../../../stores/classroom_store/lessonboard_store/LessonboardActions"
import { LessonboardContext } from "../../../../stores/classroom_store/lessonboard_store/LessonboardStore"

/**
 * Component for Resource box
 * @param {object} props.data - resource object
 * @param {object} props.topic - topic object
 * @param {string} props.title - module title
 * @returns {React.ReactElement} Resource box
 */

const ResourceBox = ({ data, topic, title }) => {
  const [classroomState, dispatch] = useContext(ClassroomContext)
  const [lessonboardStore, lessonboardDispatch] = useContext(LessonboardContext)
  const topicsResources = lessonboardStore.resources

  const handlePollNPopQuizSave = async (currentItem, value, show_results, increase_trigger_count = true) => {
    if (currentItem) {
      var formData = new FormData()
      formData.append("sequence", currentItem.sequence)
      formData.append("resource_id", currentItem.resource_id)
      formData.append("resource_type", currentItem.resource_type)
      formData.append("template", classroomState.template_id)
      formData.append("is_triggered", value)
      if (currentItem.resource_type === "quiz" && increase_trigger_count) {
        formData.append("triggered_count", parseInt(currentItem.triggered_count) + 1)
      }
      formData.append("update_version", true)
      formData.append("show_results", show_results)
      if (value || show_results) {
        formData.append("created", new Date().toISOString())
      }
      if (currentItem.resource_data.data_type === "2") {
        formData.append("end_activity", false)
      }
      return await putRequest(`/resources/${currentItem.id}/`, formData)
    }
    return {}
  }

  const triggerLessonResource = async (e) => {
    e.preventDefault()
    /*if (!["turientimage", "blog", "link", "video", "casestudy", "checklist", "quiz"].includes(data.resource_type)) {
      dispatch({ type: SET_ACTIVE_ACTIVITY_DATA, payload: { current_active_activity: { resource: data } } })
      dispatch({ type: SHOW_TRIGGERED_RESOURCE, payload: true })
      return
    }*/

    let datum_id = `?id=${data.id}&value=${topic.id}&update_version=true&training_id=${classroomState.training_id}`
    let res = await getRequest(`/lesson-board-triggering/${classroomState.template_id}/resource_poped/${datum_id}`)
    let topicId = `topic_${topic.id}`

    if (res.success) {
      let index = topicsResources[topicId].resources.findIndex((res) => res.id === data.id)
      let dummy = { ...topicsResources }
      if (data.resource_type !== "casestudy" && data.resource_type !== "quiz" && data.resource_type !== "checklist") {
        let result = await updateTriggeredCount(data)
        if (result.success) {
          dummy[topicId].resources[index] = result.data
        }
      }
      let triggeredData = res.data.triggered_data
      if (triggeredData && Object.keys(triggeredData).length > 0) {
        let obj = { version_id: triggeredData.version_id, triggered_time: triggeredData.triggered_time, triggered_count: triggeredData.triggered_count }
        if (data.resource_type === "quiz" && data.resource_data.data_type === "2") {
          obj["end_activity"] = false
          obj["show_results"] = false
        }
        dummy[topicId].resources[index] = { ...dummy[topicId].resources[index], ...obj }
      }

      //Poll & Pop-Quiz
      if (data.resource_type === "quiz" && ["1", "2"].includes(data.resource_data?.data_type)) {
        let quizResults = await handlePollNPopQuizSave(data, true, false, false)
        if (quizResults.success) {
          dummy[topicId].resources[index] = { ...dummy[topicId].resources[index], ...quizResults.data }
        }
      }

      dummy[topicId].resources[index]["topic"] = topic.id

      let currentActivity
      if (data.resource_type === "quiz" && data.resource_data.data_type === "2") {
        currentActivity = { resource: dummy[topicId].resources[index], triggered_data: {} }
        dispatch({ type: SET_ACTIVE_ACTIVITY_DATA, payload: { current_active_activity: currentActivity } })
      } else {
        currentActivity = res.data.current_active_activity
        currentActivity.resource["topic"] = topic.id
        if (
          currentActivity.resource.resource_type === "casestudy" ||
          (currentActivity.resource.resource_type === "quiz" && ["0"].includes(data.resource_data.data_type))
        ) {
          // pass
        } else {
          currentActivity.resource["triggered_count"] = parseFloat(currentActivity.resource["triggered_count"]) + 1
        }
        dispatch({ type: SET_ACTIVE_ACTIVITY_DATA, payload: { current_active_activity: currentActivity } })
      }
      let payload = { key: topicId, value: { resources: dummy[topicId].resources, resourceNext: dummy[topicId].resourceNext } }
      lessonboardDispatch({ type: SET_RESOURCE_DATA, payload })
      dispatch({ type: SHOW_TRIGGERED_RESOURCE, payload: true })
    } else {
      let currentActivity = res.data.current_active_activity
      dispatch({
        type: SET_ACTIVE_ACTIVITY_DATA,
        payload: { current_active_activity: currentActivity, active_breakout_session: res.data.active_breakout_session },
      })
      dispatch({ type: SHOW_ACTIVE_ACTIVITY, payload: true })
    }
  }

  const updateTriggeredCount = async (currentItem) => {
    let payload = {
      sequence: currentItem.sequence,
      resource_id: currentItem.resource_id,
      resource_type: currentItem.resource_type,
      triggered_count: parseInt(currentItem.triggered_count) + 1,
      show_results: currentItem.show_results,
      is_triggered: false,
      update_version: true,
    }
    if (currentItem.show_results) {
      payload["created"] = new Date().toISOString()
    }
    return await putRequest(`/resources/${currentItem.id}/`, payload)
  }

  function durationFormat(duration) {
    if (duration !== 0) {
      let seprateDuration = duration.split(":")
      if (seprateDuration[0] === "00") {
        return duration.substring(3)
      }
    } else return null
    return duration
  }

  //Begin Rendering
  let { typeId, imgObj, imgType } = getImageToBeRendered(data)
  let activeActivityResource = classroomState.activeActivityData?.current_active_activity?.resource
  let activeResourceClass = activeActivityResource?.id === data.id ? Style.bottom_green : ""
  let resourceData = data?.resource_data
  let name = resourceData?.display_name ?? resourceData?.name ?? data.resource_type

  let buttonText = data.resource_type === "video" ? "Play" : "Open"
  buttonText = data.resource_type === "casestudy" || data.resource_type === "checklist" ? "Pop" : buttonText
  buttonText = data.resource_type === "quiz" ? "Start" : buttonText

  return (
    <div className="mb-2">
      <div className={`justify-content-start p-2 ${activeResourceClass} ${Style.resources_box}`}>
        <div className="mb-0 mr-2 align-self-center flex-shrink-0">
          <Image alt={name} title={name} className={`mb-0 ${Style.resources_img}`} src={imgObj} />
        </div>
        <div className="d-flex align-self-center flex-column">
          <div className={`fs-14px elipsis-2 ${Style.resources_name}`}>{name}</div>
          {data.resource_type === "video" && <div className={`fs-12px ${Style.resources_duration}`}>{durationFormat(resourceData.resource_duration)}</div>}
        </div>
      </div>
      {title === "Doing" && activeActivityResource?.id !== data.id ? (
        <Btn onClick={triggerLessonResource} className={`my-2 ${Style.resource_action_button}`}>
          {data.is_triggered ? <FontAwesomeIcon icon={data.is_triggered === "loading" ? faSpinner : faCheck} className={Style.resource_is_triggered} /> : null}

          {typeId === 1 ? (
            <span>{data.is_triggered ? `Pop Again` : `Pop Image`}</span>
          ) : (
            <span>{parseInt(data.triggered_count) > 0 ? `${buttonText} Again` : `${buttonText} ${imgType}`}</span>
          )}
        </Btn>
      ) : null}

      {["casestudy", "quiz", "checklist"].includes(data.resource_type) && data.triggered_time !== null ? (
        <Btn
          onClick={(e) => {
            let resourceData = { ...data }
            resourceData["topic"] = topic.id
            dispatch({ type: VIEW_MODERATION_RESOURCE, payload: { resource: resourceData } })
            dispatch({ type: SHOW_TRIGGERED_RESOURCE, payload: true })
          }}
          className={`ml-2 my-2 ${Style.resource_action_button}`}
        >
          View Detail
        </Btn>
      ) : null}
      {activeActivityResource?.id === data.id && data.triggered_time === null ? (
        <Btn onClick={() => dispatch({ type: SHOW_TRIGGERED_RESOURCE, payload: true })} className={`ml-2 my-2 ${Style.resource_action_button}`}>
          Show Resource
        </Btn>
      ) : null}
    </div>
  )
}

ResourceBox.propTypes = {
  data: PropTypes.object.isRequired,
  topic: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
}

export default ResourceBox
