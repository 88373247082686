import React, { useState, useEffect, useContext } from "react"

import Accordian from "react-bootstrap/Accordion"
import Button from "react-bootstrap/Button"
import { Modal } from "react-bootstrap"

import SessionsData from "./sessionData"
import { ClassroomContext } from "../../../stores/classroom_store/ClassroomStore"
import {
  SET_ACTIVE_ACTIVITY_DATA,
  SET_ACTIVE_SECTION,
  SET_ACTIVE_SESSION,
  SET_DISCUSSION_BOARD,
  SET_TRAINING_BREAK,
  SET_BREAKOUT_GROUP_IDS,
  SET_ACTIVE_BREAKOUT_SESSION,
  SET_OPENED_OVERLAY_SECTION,
  SHOW_TRIGGERED_RESOURCE,
  SHOW_ACTIVE_ACTIVITY,
} from "../../../stores/classroom_store/ClassroomActions"
import { LessonboardContext } from "../../../stores/classroom_store/lessonboard_store/LessonboardStore"
import { TrainingSocketContext } from "../../../stores/socket/training_socket/trainingSocketProvider"
import { DataSocketContext } from "../../../stores/socket/data_socket/dataSocketProvider"
import { getRequest, postRequest } from "../../../services/rest_service"
import Image from "../../custom/Image"
import SeeMore from "../../custom/see_more"
import Btn from "../../custom/Button"

import Style from "./sessionOverview.module.scss"
import StylesA from "../../template-resources/breakout-session.module.scss"
import CancelWhite from "../../../images/svgs/close.svg"
import ArrowDown from "../../../images/svgs/arrow-dropdown-white-circle.svg"
import ArrowUp from "../../../images/svgs/arrow-dropup-white-circle.svg"

/**
 * Component to show current and upcoming sessions in live class.
 * @returns {React.ReactElement} SessionBoard component
 */
const SessionBoard = () => {
  const [classroomState, dispatch] = useContext(ClassroomContext)
  const [lessonboardStore, lessonboardDispatch] = useContext(LessonboardContext)
  const [ready, val, send] = useContext(DataSocketContext)

  const [sessions, setSessions] = useState([])
  const [sessionsNext, setSessionsNext] = useState(null)
  const [sessionsFetched, setSessionsFetched] = useState(false)
  const [showSessionList, setShowSessionList] = useState(false)
  const [endSessionModal, setEndSessionModal] = useState(null)
  const [ignoreSocket, setIgnoreSocket] = useState(false)
  const [trainingWSready, trainingWSval, trainingWSsend] = useContext(TrainingSocketContext)

  const END_SESSION_VALUES = { end_session: 0, to_next_session: 1, to_lesson_plan: 2 }

  useEffect(() => {
    fetchSessionModules()
  }, [])

  useEffect(() => {
    if (trainingWSval.event_type === "close_triggered_resource") {
      dispatch({ type: SET_ACTIVE_ACTIVITY_DATA, payload: null })
      if (trainingWSval?.resource !== "pop-quiz") {
        dispatch({ type: SHOW_TRIGGERED_RESOURCE, payload: false })
      }
    } else if (trainingWSval.event_type === "live_discussion_board" && trainingWSval.action === "started") {
      dispatch({ type: SET_ACTIVE_SECTION, payload: "DiscussionBoard" })
      dispatch({ type: SET_DISCUSSION_BOARD, payload: { ...classroomState.discussionBoard, active: trainingWSval.liveboard_id, modal: false } })
    }
  }, [trainingWSval])

  useEffect(() => {
    if (val?.component?.includes("SessionBoard")) {
      const userId = localStorage.getItem("student_id")
      if (val.fetch_type === "lbsessions" || (val.fetch_type === "resource_poped" && val.user != userId)) {
        fetchSessionModules()
      } else if (val.fetch_type === "spsessionedited") {
        updateSession(val)
      } else if (val.fetch_type === "spsessionupdate") {
        if (ignoreSocket) {
          setIgnoreSocket(false)
        } else if (val.value === "1") {
          fetchSessionModules()
        } else {
          let index = sessions.findIndex((res) => res.id === classroomState.activeSession.id)
          let current_sessions = sessions
          current_sessions.splice(index, 1)
          setSessions(current_sessions)
          dispatch({ type: SET_ACTIVE_SESSION, payload: null })
        }
      }
    }
  }, [val])

  const fetchSessionModules = async () => {
    const response = await getRequest(
      `/default-course-templates-modules-list/${classroomState.template_id}/?module_type=3&basic_data=true&lb_sequence=true&training_id=${classroomState.training_id}`
    )
    if (response.success) {
      if (response.data.active_session) {
        dispatch({ type: SET_ACTIVE_SESSION, payload: response?.data?.active_session })
      }
      dispatch({ type: SET_TRAINING_BREAK, payload: response?.data?.training_break })
      if (response?.data?.active_breakout_session) {
        dispatch({ type: SET_ACTIVE_BREAKOUT_SESSION, payload: response?.data?.breakout_active_session })
        dispatch({ type: SET_OPENED_OVERLAY_SECTION, payload: "ActiveBreakoutSession" })
      }
      setSessions(response.data.results)
      setSessionsNext(response.data.next)
      dispatch({ type: SET_BREAKOUT_GROUP_IDS, payload: response?.data?.groups })
      setSessionsFetched(true)
      if (response?.data?.live_discussion_board) {
        dispatch({ type: SET_ACTIVE_SECTION, payload: "DiscussionBoard" })
        dispatch({ type: SET_DISCUSSION_BOARD, payload: { ...classroomState.discussionBoard, active: response.data.live_discussion_board.id, modal: false } })
      }
      if (response?.data?.current_active_activity) {
        if (Object.keys(response.data.current_active_activity).length > 0) {
          dispatch({ type: SET_ACTIVE_SECTION, payload: "LessonBoard" })
          dispatch({ type: SET_ACTIVE_ACTIVITY_DATA, payload: { current_active_activity: response.data.current_active_activity } })
          let topicMural = response?.data?.current_active_activity?.triggered_data?.type === "mural"
          let breakoutSession = response?.data?.active_breakout_session
          if (!(topicMural || breakoutSession)) {
            dispatch({ type: SHOW_TRIGGERED_RESOURCE, payload: true })
          }
        }
      }
    } else {
      // TODO: add global error handling
    }
  }

  const handleNextData = (response) => {
    let current_sessions = [...sessions]
    current_sessions = current_sessions.concat(response.data.results)
    setSessions(current_sessions)
    setSessionsNext(response.data.next)
  }

  const updateSession = (websocket_data) => {
    let id = parseInt(websocket_data.id.split("_$_")[0])
    let value = websocket_data.id.split("_$_")[1]
    let field_name = websocket_data.value
    let index = sessions.findIndex((session) => session.id === id)
    if (index > -1) {
      let current_sessions = [...sessions]
      current_sessions[index][field_name] = value
      setSessions(current_sessions)
    }
  }

  const startSession = async (session) => {
    try {
      let session_id = session.id
      let index = sessions.findIndex((session) => session.id === session_id)
      let response = await postRequest(`/module-utils/${session_id}/start_session/`)
      if (response.success) {
        // This will trigger useeffect in lesson board to fetch current session topics.
        dispatch({ type: SET_ACTIVE_SESSION, payload: { name: session.name, id: session_id } })
        let current_sessions = [...sessions]
        current_sessions[index].session_type = "1"
        setSessions(current_sessions)

        // Trigger websocket to update session status in student side and class content.
        let websocket_data = {
          event_type: "lesson_board",
          fetch_type: "spsessionupdate",
          id: session.id,
          value: "1",
        }
        setIgnoreSocket(true)
        send(JSON.stringify(websocket_data))
      } else {
        // TODO: add global error handling
      }
    } catch (error) {
      console.error(error)
    }
  }
  function handleEndSession(session) {
    if (classroomState.activeActivityData?.current_active_activity && classroomState.activeActivityData?.current_active_activity?.id) {
      dispatch({ type: SHOW_ACTIVE_ACTIVITY, payload: true })
      return
    }
    // If there are pending topics in To Do or Doing then we have to show warning modal and trainer has to choose what to do with those topics.
    let pendingTopics = lessonboardStore.topics["module_To_Do"].topics.length + lessonboardStore.topics["module_Doing"].topics.length
    if (pendingTopics > 0) {
      setEndSessionModal(session)
    } else {
      endSession(session)
    }
  }
  const endSession = async (datum, type = "end_session") => {
    let index = sessions.findIndex((res) => res.id === datum.id)
    datum.session_type = "2"
    if (datum.id) {
      let formData = new FormData()
      formData.append("end_option", END_SESSION_VALUES[type])
      let response = await postRequest(`/module-utils/${datum.id}/end_session/`, formData)
      if (response.success) {
        let current_sessions = sessions
        current_sessions.splice(index, 1)
        setSessions(current_sessions)
        // This will trigger useeffect in lesson board and clear all the topics.
        dispatch({ type: SET_ACTIVE_SESSION, payload: null })
        // Trigger websocket to update session status in student side and class content.
        let websocket_data = {
          event_type: "lesson_board",
          fetch_type: "spsessionupdate",
          id: datum.id,
          value: `2_$_${type === "to_next_session" && sessions.length > 1 ? sessions[index + 1].id : type}`,
        }
        setIgnoreSocket(true)
        send(JSON.stringify(websocket_data))
        setEndSessionModal(null)
      }
    }
  }

  const sessionEndModal = () => {
    return (
      <Modal
        show={endSessionModal ? true : false}
        onHide={(e) => {
          setEndSessionModal(null)
        }}
        centered
        className={`${StylesA.classroom_delete_modal} classroom-resource-delete-modal`}
      >
        <Modal.Header className={`border-0 bg-121B2B ${Style.end_session_modal_header}`}>
          <Image
            onClick={(e) => {
              e.preventDefault()
              setEndSessionModal(null)
            }}
            src={CancelWhite}
            alt={`Cancel`}
            className={`my-auto mr-1 pointer ${Style.end_session_modal_close}`}
          />
        </Modal.Header>
        <Modal.Body className={`text-center pt-0 bg-121B2B ${Style.end_session_modal_body}`}>
          <p className={`mb-3 px-5 fs-16px turient-header-text-color ${Style.end_session_modal_text}`}>
            There are unfinished topics in this session. What would you like to do? <br />
            <span className="fs-14px">Session: {endSessionModal.name}</span>
          </p>
          <div className={`mx-auto ${Style.end_session_modal_btn_contaier}`}>
            <Btn
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                endSession(endSessionModal)
              }}
              className={`w-100 d-block btn mb-2 fs-14px bg-303C54 turient-header-text-color `}
            >
              Yes, End the Session{" "}
            </Btn>
            {sessions.length > 1 ? (
              <Btn
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  endSession(endSessionModal, "to_next_session")
                }}
                className={`w-100 d-block btn bg-303C54 fs-14px mb-2 turient-header-text-color `}
              >
                Move to Next Session{" "}
              </Btn>
            ) : null}
            <Btn
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                endSession(endSessionModal, "to_lesson_plan")
              }}
              className={`bg-303C54 btn mb-2 fs-14px turient-header-text-color`}
            >
              Move to Unscheduled Topics{" "}
            </Btn>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  // First session is always visible, rest will be inside accordian.
  let [title_session, ...dropdown_sessions] = sessions

  return (
    <React.Fragment>
      <Accordian>
        {sessions.length > 0 ? (
          <div className={`d-flex w-100 my-2 flex-column inner-element-box-shadow ${Style.sb_accordian}`}>
            <div className={`d-flex px-2 justify-content-between ${Style.sb_border_bottom}`}>
              <div className={`align-self-center ${sessions.length === 1 ? `` : `py-2`}`}>Sessions Overview</div>
              <Accordian.Toggle
                as={Button}
                variant={"none"}
                onClick={() => setShowSessionList(!showSessionList)}
                className={`text-white shadow-none ${sessions.length === 1 ? `d-none` : ``}`}
                eventKey="1"
              >
                <Image alt="liveclass-accordian-toggle" src={showSessionList ? ArrowUp : ArrowDown} className={`${Style.accordian_toggle_icon}`} />
              </Accordian.Toggle>
            </div>
            <SessionsData session={title_session} type={`active_session`} handleEndSession={handleEndSession} startSession={startSession} />
            <Accordian.Collapse
              eventKey="1"
              onScroll={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
              className={`${Style.sb_accordian_collapse}`}
            >
              <>
                {dropdown_sessions.map((session, index) => (
                  <SessionsData key={`session_${session.id}`} session={session} handleEndSession={handleEndSession} startSession={startSession} />
                ))}
                {sessionsNext ? (
                  <div className={`text-center mb-2`}>
                    <SeeMore url={sessionsNext} handleData={handleNextData} />
                  </div>
                ) : null}
              </>
            </Accordian.Collapse>
          </div>
        ) : null}
      </Accordian>
      {endSessionModal ? sessionEndModal() : null}
    </React.Fragment>
  )
}

export default SessionBoard
