import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"

import SeeMore from "../../../custom/see_more"
import ResourceBox from "./resourceBox"
import Loader from "../../../custom/Loader"
import Image from "../../../custom/Image"
import FeedbackIcon from "./feebackIcon"
import BreakoutSessionBox from "./breakoutSessionBox"
import WorkspaceBox from "./workspaceBox"

import { getRequest } from "../../../../services/rest_service"
import { puralWord } from "../../../../services/common"

import ArrowDown from "../../../../images/svgs/arrow-dropdown-white-circle.svg"
import ArrowUp from "../../../../images/svgs/arrow-dropup-white-circle.svg"
import BigQuestion from "../../../../images/svgs/BigQuestion.svg"
import happy from "../../../../images/svgs/happy.svg"
import love from "../../../../images/svgs/love.svg"
import bored from "../../../../images/svgs/bored.svg"
import sleepy from "../../../../images/svgs/sleepy.svg"

import { ClassroomContext } from "../../../../stores/classroom_store/ClassroomStore"
import { QuestionContext } from "../../../../stores/question_store/QuestionStore"
import { LessonboardContext } from "../../../../stores/classroom_store/lessonboard_store/LessonboardStore"
import { DataSocketContext } from "../../../../stores/socket/data_socket/dataSocketProvider"
import { SET_SHOW_QUESTION_MODAL } from "../../../../stores/question_store/QuestionActions"
import { SET_RESOURCE_DATA } from "../../../../stores/classroom_store/lessonboard_store/LessonboardActions"

import Style from "../style.module.scss"

/**
 * Component for Topic Card
 * @param {object} props.topic - topic data object
 * @param {string} props.moduleName - module name
 * @returns {React.ReactElement} Topic Card
 */

const TopicCard = ({ topic, moduleName }) => {
  const [classroomState, dispatch] = useContext(ClassroomContext)
  const [questionStore, questionDispatch] = useContext(QuestionContext)
  const [lessonboardStore, lessonboardDispatch] = useContext(LessonboardContext)
  const [dataWSready, dataWSval, dataWSsend] = useContext(DataSocketContext)

  const [expand, setExpand] = useState(false)
  const topicsResources = lessonboardStore.resources
  const key = `topic_${topic?.id}`

  const handleToggle = (isToggle) => {
    if (isToggle) {
      setExpand((prev) => !prev)
    } else {
      setExpand(true)
    }
    if (!topicsResources[key]) {
      fetchTopicResources()
    }
  }

  useEffect(() => {
    if (lessonboardStore.draggedItem) {
      let { draggableId, source, destination } = lessonboardStore.draggedItem
      if (destination) {
        if (draggableId.split("-")[0] == topic?.id && source.droppableId === "module_To_Do" && destination.droppableId === "module_Doing") {
          handleToggle(false)
        }
      }
    }
  }, [lessonboardStore.draggedItem])

  useEffect(() => {
    if (dataWSval && dataWSval.fetch_type === "lessonresources" && (topic?.id === parseInt(dataWSval.id) || topic?.id === parseInt(dataWSval.value))) {
      if (topicsResources[key]) {
        fetchTopicResources()
      }
    }
  }, [dataWSval])

  const fetchTopicResources = async () => {
    try {
      const response = await getRequest(
        `/resources-names-list/?paginate_by=8&topic=${topic?.id}&is_poll=false&template_id=${classroomState.template_id}&for_trigger=true`
      )
      if (response.success) {
        let payload = { key, value: { resources: response.data.results, resourceNext: response.data.next } }
        lessonboardDispatch({ type: SET_RESOURCE_DATA, payload })
      } else {
        // TODO: show error msg
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleNextResourceFetch = (response) => {
    let resources = { ...topicsResources }
    let payload = { key, value: { resources: [...resources[key].resources, ...response.data.results], resourceNext: response.data.next } }
    lessonboardDispatch({ type: SET_RESOURCE_DATA, payload })
  }

  const handleOpenQuestionModal = (e) => {
    e.preventDefault()
    let modalData = { type: "question_list", title: topic?.name, id: topic?.id }
    questionDispatch({ type: SET_SHOW_QUESTION_MODAL, payload: modalData })
  }

  return (
    <div className={`mb-2 ${Style.topic_card} ${moduleName === "Done" ? Style.bottom_green : ""}`}>
      <div className="px-2 pt-2 d-flex justify-content-between">
        <div className={`mr-1 ${Style.topic_name}`}>{topic?.name}</div>
        <div
          className="align-self-start pointer"
          onClick={() => {
            handleToggle(true)
          }}
        >
          <img className="mb-1 icon" src={expand ? ArrowUp : ArrowDown} />
        </div>
      </div>
      {!expand ? (
        <div className="d-flex justify-content-between mt-2 px-2 pb-2 text-grey fs-10px">
          <div>{puralWord(topic?.questions?.count, "Question")}</div>
          <div>{puralWord(topic?.feedbacks?.count, "Feedback")}</div>
        </div>
      ) : (
        <div className={`p-2 ${Style.border_top}`}>
          {topicsResources[key]?.resources ? (
            topicsResources[key].resources.map((resource, index) => (
              <ResourceBox key={`${index}resource${resource.id}`} data={resource} topic={topic} title={moduleName} />
            ))
          ) : (
            <div className={`w-100 text-center`}>
              <Loader />
            </div>
          )}
          {topicsResources && topicsResources[key]?.resourceNext ? (
            <SeeMore url={topicsResources[key].resourceNext} handleData={handleNextResourceFetch} />
          ) : null}

          {topic?.breakout_sessions?.map((session, index) => {
            return <BreakoutSessionBox key={`br_box_${index}`} session={session} title={moduleName} />
          })}
          {topic?.activity_workspace?.link ? <WorkspaceBox topic={topic} title={moduleName} /> : null}

          {topic?.questions?.count > 0 && (
            <div className={`p-2 mt-2 pointer ${Style.resources_box}`} onClick={handleOpenQuestionModal}>
              <div className="pt-1">
                <Image alt="question" className="mb-0" src={BigQuestion} />
              </div>
              <div className="d-flex pl-2 flex-fill flex-column">
                <div className="fs-16px">{puralWord(topic?.questions.count, "Question")}</div>
                <div className="fs-12px text-grey">{topic?.questions.un_answered_count} Unanswered</div>
              </div>
            </div>
          )}
          <div className={`text-center justify-content-between p-2 mt-2 ${Style.resources_box}`}>
            <FeedbackIcon image={love} count={topic?.feedbacks.like} color="#E88E79" />
            <FeedbackIcon image={happy} count={topic?.feedbacks.wow} color="#E8E879" />
            <FeedbackIcon image={bored} count={topic?.feedbacks.love} color="#89C5F8" />
            <FeedbackIcon image={sleepy} count={topic?.feedbacks.funny} color="#B3BAF0" />
          </div>
        </div>
      )}
    </div>
  )
}

TopicCard.propTypes = {
  topic: PropTypes.object.isRequired,
  moduleName: PropTypes.string.isRequired,
}

export default TopicCard
