import React, { useState, useEffect, useContext } from "react"

import { getRequest } from "../../../services/rest_service"
import { ClassroomContext } from "../../../stores/classroom_store/ClassroomStore"
import { QuestionContext } from "../../../stores/question_store/QuestionStore"

import Image from "../../custom/Image"
import Loader from "../../custom/Loader"
import Btn from "../../custom/Button"
import QuestionBox from "../QuestionBox"

import Style from "../style.module.scss"

import BigQuestion from "../../../images/svgs/BigQuestion.svg"

import { SET_SHOW_QUESTION_MODAL, SET_QUESTION_DATA, SET_QUESTION_NEXT_DATA } from "../../../stores/question_store/QuestionActions"

/**
 * Component for Sidebar Question List
 * @returns {React.ReactElement} Question List modal
 */

const QuestionSidebar = () => {
  const [classroomStore, classroomDispatch] = useContext(ClassroomContext)
  const [questionStore, questionDispatch] = useContext(QuestionContext)

  let questionsData = questionStore.question_data
  let questionsNextUrl = questionStore.question_next_data

  const [onScrollLoading, setOnScrollLoading] = useState(false)
  const [questionsDataFetched, setQuestionsDataFetched] = useState(false)

  useEffect(() => {
    fetchQuestions("all_questions")
  }, [])

  const fetchQuestions = async (object_id) => {
    let key = "topic_" + object_id
    let url = `&object_id=${object_id}`
    if (object_id === "all_questions") {
      url = `&training=${classroomStore.training_id}`
    }
    let res = await getRequest(`/queries/?basic-data=true&content_type=topic&paginate_by=8&ordering=-id&topic_stage_name=Workshop${url}`)
    if (res.success) {
      let data = { ...questionsData }
      let urlData = { ...questionsNextUrl }
      data[key] = res.data.results
      urlData[key] = res.data.next
      questionDispatch({ type: SET_QUESTION_DATA, payload: data })
      questionDispatch({ type: SET_QUESTION_NEXT_DATA, payload: urlData })
      setQuestionsDataFetched(true)
    }
  }

  const handleFetchNextQuestion = async () => {
    if (!questionsNextUrl["topic_all_questions"]) return

    setOnScrollLoading(true)
    let response = await getRequest(questionsNextUrl["topic_all_questions"])
    if (response.success) {
      let key = `topic_all_questions`
      let urlData = { ...questionsNextUrl }
      urlData[key] = response.data.next
      let data = { ...questionsData }
      let quesData = data[key]
      quesData = [...quesData, ...response.data.results]
      data[key] = quesData
      questionDispatch({ type: SET_QUESTION_DATA, payload: data })
      questionDispatch({ type: SET_QUESTION_NEXT_DATA, payload: urlData })
      setOnScrollLoading(false)
    }
  }

  const handleViewAllClick = () => {
    let modalData = { type: "question_list", title: "All Questions", id: "modal_all_questions" }
    questionDispatch({ type: SET_SHOW_QUESTION_MODAL, payload: modalData })

    if (questionsData && questionsData.topic_all_questions) {
      let data = { ...questionsData }
      let urlData = { ...questionsNextUrl }
      data["topic_modal_all_questions"] = JSON.parse(JSON.stringify(data["topic_all_questions"]))
      urlData["topic_modal_all_questions"] = JSON.parse(JSON.stringify(urlData["topic_all_questions"]))
      questionDispatch({ type: SET_QUESTION_DATA, payload: data })
      questionDispatch({ type: SET_QUESTION_NEXT_DATA, payload: urlData })
    }
  }

  const handleQuestionScroll = (e) => {
    e.stopPropagation()
    let div = e.target
    if (div.scrollTop + div.clientHeight >= div.scrollHeight - 20 && questionsNextUrl["topic_all_questions"] && !onScrollLoading) {
      handleFetchNextQuestion()
    }
  }

  return (
    <>
      <div className="p-2">
        <div className={`d-inline-block align-items-center p-2 w-100 ${Style.sidebar_box}`}>
          <div className="d-inline-flex align-items-center w-100 pointer pb-3">
            <div className="pt-1">
              <Image alt="question" className="mb-0" src={BigQuestion} />
            </div>
            <div className={`pl-2 fs-16px`}>All Questions</div>
            <Btn type="small" className={`px-3 ml-auto bg-303C54`} onClick={handleViewAllClick}>
              View All
            </Btn>
          </div>
          <div className={`w-100 align-items-center ${Style.question_scroll}`} onScroll={handleQuestionScroll}>
            {questionsDataFetched && questionsData["topic_all_questions"]?.length === 0 && (
              <div className="text-center py-2 w-100">
                <p className={`mb-2 fs-14px`}>No questions from students</p>
              </div>
            )}
            {questionsDataFetched &&
              questionsData["topic_all_questions"]?.map((data, index) => {
                return <QuestionBox key={`question-box-list-${index}`} data={data} index={index} isSidebarList={true} />
              })}
            {(!questionsDataFetched || onScrollLoading) && (
              <div className="text-center py-2 w-100">
                <Loader />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default QuestionSidebar
