import { SET_RESOURCE_DATA, SET_STUDENT_DATA, SET_VERSION_DATA } from "./TriggeredResourceActions"

const TriggeredResourceReducer = (state, action) => {
  switch (action.type) {
    case SET_RESOURCE_DATA:
      return { ...state, resourceData: action.payload }
    case SET_STUDENT_DATA:
      return { ...state, studentsData: action.payload }
    case SET_VERSION_DATA:
      return { ...state, versionData: action.payload }
    default:
      return state
  }
}

export default TriggeredResourceReducer
