import React, { useCallback, useContext, useEffect, useState } from "react"
import debounce from "lodash/debounce"
import QuestionModal from "../QuestionModal"
import Loader from "../../custom/Loader"
import QuestionBox from "../QuestionBox"
import QuestionFilters from "./questionFilters"
import Search from "./search"

import Style from "../style.module.scss"

import { ClassroomContext } from "../../../stores/classroom_store/ClassroomStore"
import { QuestionContext } from "../../../stores/question_store/QuestionStore"

import { SET_QUESTION_DATA, SET_QUESTION_NEXT_DATA } from "../../../stores/question_store/QuestionActions"

import { getRequest } from "../../../services/rest_service"

/**
 * Component to display list of questions in modal.
 * @returns {React.ReactElement} Question List modal
 */

const QuestionsList = () => {
  const [classroomStore, classroomDispatch] = useContext(ClassroomContext)
  const [questionStore, questionDispatch] = useContext(QuestionContext)

  const questionsData = questionStore.question_data
  const questionsNextUrl = questionStore.question_next_data
  const topicId = questionStore.show_question_modal.id
  const key = `topic_${topicId}`

  const [questionList, setQuestionList] = useState([])
  const [nextUrl, setNextUrl] = useState(null)
  const [loader, setLoader] = useState({ all: null, searchInput: false })
  const [selectedSession, setSelectedSession] = useState(0)
  const [searchInput, setSearchInput] = useState("")

  useEffect(() => {
    if (questionStore.show_question_modal) {
      if (questionsData && questionsData[key]) {
        setQuestionList(questionsData[key])
        setNextUrl(questionsNextUrl[key])
        setLoader((prev) => ({ ...prev, all: false }))
      } else {
        fetchQuestions(topicId, true)
      }
    }
  }, [questionStore.show_question_modal])

  const handleSessionFilters = (id) => {
    setSelectedSession(id)
    fetchQuestions("modal_all_questions", true, id, searchInput)
  }

  const debouncedSearchQuestion = useCallback(
    debounce((topicID, refetch, selectedSession, searchInput) => fetchQuestions(topicID, refetch, selectedSession, searchInput), 1000),
    []
  )

  const handleSearchInput = (e) => {
    setSearchInput(e.target.value)
    setLoader((prev) => ({ ...prev, searchInput: true }))
    debouncedSearchQuestion(topicId, true, selectedSession, e.target.value)
  }

  const fetchQuestions = async (object_id, refetch = false, selectedSession = 0, searchInput = "") => {
    if (refetch) {
      let url = `&object_id=${object_id}`
      setLoader((prev) => ({ ...prev, all: true }))
      if (object_id === "modal_all_questions") {
        url = `&training=${classroomStore.training_id}`
      }
      if (searchInput !== "") {
        url = `${url}&search=${searchInput}`
      }
      if (selectedSession > 0) {
        url = `${url}&topic__module__id=${selectedSession}`
      }
      let res = await getRequest(`/queries/?basic-data=true&content_type=topic&paginate_by=8&ordering=-id&topic_stage_name=Workshop${url}`)

      if (res.success) {
        if (!refetch) {
          let data = { ...questionsData }
          let urlData = { ...questionsNextUrl }
          data[key] = res.data.results
          urlData[key] = res.data.next
          questionDispatch({ type: SET_QUESTION_DATA, payload: data })
          questionDispatch({ type: SET_QUESTION_NEXT_DATA, payload: urlData })
        }
        setQuestionList(res.data.results)
        setNextUrl(res.data.next)
        setLoader({ all: false, searchInput: false })
      }
    }
  }

  const fetchNextQuestions = async () => {
    if (!nextUrl) return

    setLoader((prev) => ({ ...prev, all: true }))

    let res = await getRequest(nextUrl)
    if (res.success) {
      let urlData = { ...questionsNextUrl }
      urlData[key] = res.data.next
      let data = { ...questionsData }
      let quesData = data[key]
      quesData = [...quesData, ...res.data.results]
      data[key] = quesData
      questionDispatch({ type: SET_QUESTION_DATA, payload: data })
      questionDispatch({ type: SET_QUESTION_NEXT_DATA, payload: urlData })
      setQuestionList(quesData)
      setNextUrl(res.data.next)
      setLoader((prev) => ({ ...prev, all: false }))
    } else {
      console.error(res.data.msg)
    }
  }

  const handleQuestionScroll = (e) => {
    e.stopPropagation()
    let div = e.target
    if (div.scrollTop + div.clientHeight >= div.scrollHeight - 20 && nextUrl && !loader.all) {
      fetchNextQuestions()
    }
  }

  return (
    <QuestionModal title={questionStore.show_question_modal.title}>
      <Search handleSearchInput={handleSearchInput} loader={loader.searchInput} />
      {topicId === "modal_all_questions" && <QuestionFilters selectedSession={selectedSession} handleSessionFilters={handleSessionFilters} />}
      <div onScroll={handleQuestionScroll} className={`p-0 overflow-y-scroll ${Style.scrollable_body}`}>
        {!loader.all && questionList.length === 0 && (
          <div className="text-center py-2 w-100">
            <p className={`mb-2 fs-14px`}>No questions from students</p>
          </div>
        )}
        <>
          {questionList.map((data, index) => (
            <QuestionBox key={`question-box-list-${index}`} data={data} index={index} isSidebarList={false} />
          ))}
        </>

        {loader.all && (
          <div className="text-center py-2 w-100">
            <Loader />
          </div>
        )}
      </div>
    </QuestionModal>
  )
}

export default QuestionsList
