import React, { useContext, useEffect } from "react"
import PropTypes from "prop-types"

import TopicCard from "./topicCard"
import SeeMore from "../../../custom/see_more"

import { Draggable, Droppable } from "react-beautiful-dnd"

import { ClassroomContext } from "../../../../stores/classroom_store/ClassroomStore"
import { LessonboardContext } from "../../../../stores/classroom_store/lessonboard_store/LessonboardStore"
import { SET_TOPIC_DATA } from "../../../../stores/classroom_store/lessonboard_store/LessonboardActions"

import Style from "../style.module.scss"

/**
 * Component for Module Card
 * @param {object} props.data - module data object
 * @param {func} props.fetchTopics - function to fetch Topic list
 * @returns {React.ReactElement} Module Card
 */

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#000" : "#303C54",
  border: isDraggingOver ? "1px dotted white" : "0px solid white",
  padding: 8,
})
const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  outline: "none",
  padding: 8,
  margin: `0`,
  borderRadius: "20px",
  textAlign: "left", //center
  color: "#fff",
  // background: isDragging ? "#000" : "#303C54",
  // styles we need to apply on draggables
  ...draggableStyle,
})

const ModuleCard = ({ data, fetchTopics }) => {
  const [classroomState, dispatch] = useContext(ClassroomContext)
  const [lessonboardStore, lessonboardDispatch] = useContext(LessonboardContext)

  const modulesData = lessonboardStore.topics
  const key = `module_${data.name.replaceAll(" ", "_")}`

  useEffect(() => {
    if (classroomState.activeSession) {
      fetchTopics(key, data.id)
    }
  }, [classroomState.activeSession])

  const handleNextTopicFetch = (response) => {
    let topics = [...modulesData[key].topics, ...response.data.results]
    lessonboardDispatch({ type: SET_TOPIC_DATA, payload: { key, value: { topics, topicNext: response.data.next } } })
  }

  const _getModuleCardClassName = (name) => {
    if (name == "To Do") {
      return `mr-2 ${Style.module_card}`
    }
    return `mx-2 ${Style.module_card}`
  }

  return (
    <div className={_getModuleCardClassName(data.name)}>
      <div className={`p-2 ${Style.border_bottom}`}>{data.name}</div>
      <div className={`${Style.card_scrollable} h-100`}>
        <Droppable droppableId={key} type={`topicDraggable`}>
          {(provided, snapshot) => (
            <div className={`bg-transparent h-100`} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
              {modulesData && modulesData[key]
                ? modulesData[key].topics.map((item, index) => {
                    return (
                      <Draggable key={item?.id + `_topic_name_` + index} draggableId={`${item?.id}-topic_${index}`} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <TopicCard topic={item} moduleName={data.name} />
                          </div>
                        )}
                      </Draggable>
                    )
                  })
                : null}
              <div>{provided.placeholder}</div>
              {modulesData && modulesData[key]?.topicNext ? (
                <div className={`pl-2`}>
                  <SeeMore url={modulesData[key].topicNext} handleData={handleNextTopicFetch} />
                </div>
              ) : null}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  )
}

ModuleCard.propTypes = {
  data: PropTypes.object.isRequired,
  fetchTopics: PropTypes.func.isRequired,
}

export default ModuleCard
