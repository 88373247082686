import React from "react"

import QuestionStore from "../../../../stores/question_store/QuestionStore"
import Classroom from "../../../../components/live_class/classroom/Classroom"
import BreakoutGroupSocketConsumer from "../../../../stores/socket/breakout_group_socket/breakoutGroupSocketConsumer"
import TrainingSocketConsumer from "../../../../stores/socket/training_socket/trainingSocketConsumer"
import DataSocketConsumer from "../../../../stores/socket/data_socket/dataSocketConsumer"
import LessonboardStore from "../../../../stores/classroom_store/lessonboard_store/LessonboardStore"
import TriggeredResourceStore from "../../../../stores/classroom_store/triggered_resource_store/TriggeredResourceStore"

import Encryption from "../../../../services/encryption"
// import { isBrowser } from "../../../../services/developer_mode"

var encryptionObj = new Encryption()

const ClassroomWrapper = (location) => {
  // let encryptedTrainingId = decodeURIComponent(location.training_id)
  // const trainingId = isBrowser() ? parseInt(encryptionObj.decryption(encryptedTrainingId)) : ""
  let trainingId = location.training_id

  return (
    <BreakoutGroupSocketConsumer link={"/ws/breakout-groups/"}>
      <TrainingSocketConsumer link={`/ws/training-activity/${trainingId}/`}>
        <DataSocketConsumer link={`/ws/fetch-data/${location.template_id}/`}>
          <LessonboardStore>
            <QuestionStore>
              <TriggeredResourceStore>
                <Classroom trainingId={trainingId} templateId={location.template_id} />
              </TriggeredResourceStore>
            </QuestionStore>
          </LessonboardStore>
        </DataSocketConsumer>
      </TrainingSocketConsumer>
    </BreakoutGroupSocketConsumer>
  )
}

export default ClassroomWrapper
