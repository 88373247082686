import React from "react"
import PropTypes from "prop-types"
import Image from "../../../custom/Image"
import Style from "../style.module.scss"

/**
 * Component for Feedback count icon
 * @param {string} props.image - feedback image
 * @param {Number} props.count - feedback count
 * @param {string} props.color - feedback color for text
 * @returns {React.ReactElement} Feedback count icon
 */

const FeedbackIcon = ({ image, count, color }) => {
  return (
    <div>
      <Image className={Style.feeback_icon} alt="feeback icon" src={image} />
      <span style={{ color }}>{count}</span>
    </div>
  )
}

FeedbackIcon.propTypes = {
  image: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
}

export default FeedbackIcon
