import React from "react"
import TimeDifference from "../../utilities/timeDifference"
import OverlayTooltip from "../../custom/OverlayTooltip"
import PropTypes from "prop-types"
import Btn from "../../custom/Button"
import Image from "../../custom/Image"

import Style from "./sessionOverview.module.scss"

import Clock from "../../../images/svgs/clock-black.svg"
import LessonBoardInsight from "../../insights/LessonBoardInsight"

/**
 * Component to show one session data in session overview.
 * @param {object} props
 * @param {object} props.session
 * @param {string} props.session.session_type
 * @param {string} props.session.description
 * @param {string} props.session.name
 * @param {func} props.handleEndSession
 * @param {func} props.startSession
 * @returns {React.ReactElement} SessionsData component
 */

const SessionsData = (props) => {
  let desc = props.session?.description ?? ""
  let sessionNameDesc = props.session.name + (desc.length > 0 ? ` : ${desc}` : "")
  let sessionNameDescTooltip =
    "<span><b>Name: </b>" + props.session.name + "<br /><b>Objective: </b>" + (desc.length > 0 ? desc.replace(/\r?\n/g, "<br />") : "") + "</span>"

  return (
    <div
      className={`d-flex align-items-center br-9px bg-42506C
      mx-3 my-2 pr-2 py-2 justify-content-between inner-element-box-shadow ${props.session.session_type === "0" ? "pointer" : ""} 
      border_on_hover  ${props.session.session_type === "1" && "active-session-bg"}`}
    >
      <div className="mr-5 flex-shrink-0 fs-16px">
        <Image src={Clock} alt={`clock`} className={`${Style.clock_icon}`} />
        {TimeDifference.getTimeDifference(props.session.start_date, props.session.end_date)}
      </div>
      <div className={`d-flex flex-grow-1 flex-wrap justify-content-between fs-16px`}>
        <div className={`py-2 text-truncate ${Style.sd_tooltip_width}`}>
          {sessionNameDescTooltip.length > 30 ? (
            <OverlayTooltip tooltipText={sessionNameDescTooltip} tooltipClass={`${Style.sd_tooltip}`} text={sessionNameDesc} format="html" />
          ) : (
            <span>{sessionNameDesc}</span>
          )}
        </div>
        {process.env.GATSBY_REACT_APP_ENABLE_INSIGHTS === "true" && (
          <div className={`align-self-center`}>
            <LessonBoardInsight session={props.session} />
          </div>
        )}

        {props.session.session_type === "0" || props.session.session_type === "1" ? (
          <div className={`align-self-center`}>
            <Btn
              onClick={() => {
                props.session.session_type === "0" ? props.startSession(props.session) : props.handleEndSession(props.session)
              }}
              className={`btn fs-14px turient-header-text-color ${Style.sd_button}`}
              disabled={props.type !== "active_session"}
              style={{ opacity: props.type !== "active_session" ? ".5" : "1" }}
            >
              {props.session.session_type === "0" ? "Start Session" : "End Session"}
            </Btn>
          </div>
        ) : null}
      </div>
    </div>
  )
}

SessionsData.propTypes = {
  // To identify the first session
  type: PropTypes.string,

  session: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    session_type: PropTypes.string.isRequired,
  }).isRequired,
  handleEndSession: PropTypes.func.isRequired,
  startSession: PropTypes.func.isRequired,
}

export default SessionsData
