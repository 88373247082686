import React, { useState, useContext } from "react"
import { Link, navigate } from "gatsby"
import Image from "../../custom/Image"
import { ClassroomContext } from "../../../stores/classroom_store/ClassroomStore"
import { SET_PRIORITY_CLASSROOM_NOTIFICATION_MODAL } from "../../../stores/classroom_store/ClassroomActions"
import { putRequest } from "../../../services/rest_service"
import Btn from "../../custom/Button"
import PropTypes from "prop-types"
import Loadable from "@loadable/component"
import cancelWhite from "../../../images/svgs/close.svg"
import arrow from "../../../images/svgs/down_arrow_white.svg"
import share from "../../../images/share.png"
import eye from "../../../images/classroom/eye-icon.png"
import Style from "./header.module.scss"
const SharedArtifact = Loadable(() => import("../../shared-artifact"))
const PriorityClassroomNotifcationModal = Loadable(() => import("../../notification/priority_classroom_notification_modal"))
const NotificatioBellAndModal = Loadable(() => import("./notificationBellAndModal"))

// !definition of component
/**
 * @param props --> trainingDetail
 * @description --> trainingDetail => To get course name
 * @returns Header component
 */
// ! component

const Header = (props) => {
  const [showSharedArtifact, setShowSharedArtifact] = useState(false)
  const [classroomState, dispatch] = useContext(ClassroomContext)

  const showPriorityClassroomNotifcationModal = classroomState.priorityClassroomNotifcationModal
  const setShowPriorityClassroomNotifcationModal = (value) => {
    dispatch({ type: SET_PRIORITY_CLASSROOM_NOTIFICATION_MODAL, payload: value })
  }

  async function changeTrainingStatus() {
    let class_status = "3"
    let end_dt = new Date(props.trainingDetail.end_date)
    let current_dt = new Date()
    current_dt = new Date(Date.UTC(current_dt.getUTCFullYear(), current_dt.getUTCMonth(), current_dt.getUTCDate()))
    end_dt = new Date(Date.UTC(end_dt.getUTCFullYear(), end_dt.getUTCMonth(), end_dt.getUTCDate()))
    let currDate = current_dt.toISOString().slice(0, 10)

    if (current_dt.getTime() < end_dt.getTime()) {
      class_status = "2"
    }
    let formData = new FormData()
    let response = await putRequest(`/change-training-state/${classroomState.training_id}/?status=${class_status}&date=${currDate}`, formData)
    if (response.success) {
      navigate(`/main/class-dashboard/${classroomState.template_id}/${classroomState.training_id}/`)
    }
  }

  return (
    <React.Fragment>
      <div className={`${Style.live_classroom_header} py-3 d-flex`}>
        <div className={`d-flex align-items-center`}>
          <Link to={`/main/class-dashboard/${classroomState.template_id}/${classroomState.training_id}`}>
            <Image src={arrow} className="icon mr-2" style={{ transform: "rotate(90deg)", width: "20px" }} />
          </Link>

          <h5 className="mb-0">{props.trainingDetail.course}</h5>
          <div className={`d-flex align-items-center ${Style.live_img_container}`}>
            <Image className={`${Style.live_img}`} alt="live course" src={eye} />
            Live
          </div>
        </div>
        <div>
          <NotificatioBellAndModal circle={false} notficationType="1" trainingId={classroomState.training_id} />
          <Btn
            onClick={() => setShowSharedArtifact(true)}
            variant="none"
            className={`border_on_hover mr-3 text-center align-items-center d-inline-flex text-white ${Style.button}`}
          >
            <Image src={share} alt={`Share Course`} className={`my-auto mr-1 ${Style.btn_img}`} />
            <p className={`${Style.button_text}`}>Share Class room</p>
          </Btn>
          <Btn
            onClick={() => changeTrainingStatus()}
            variant="none"
            className={`border_on_hover text-center align-items-center d-inline-flex text-white ${Style.button}`}
          >
            <img src={cancelWhite} alt={`Cancel`} className={`my-auto mr-1 ${Style.btn_img}`} />
            <p className={`${Style.button_text}`}>End Class</p>
          </Btn>
        </div>
      </div>
      {showSharedArtifact && <SharedArtifact show={showSharedArtifact} hide={() => setShowSharedArtifact(false)} trainingId={classroomState.training_id} />}
      {showPriorityClassroomNotifcationModal && (
        <PriorityClassroomNotifcationModal show={showPriorityClassroomNotifcationModal} hide={() => setShowPriorityClassroomNotifcationModal(false)} />
      )}
    </React.Fragment>
  )
}

Header.propTypes = {
  trainingDetail: PropTypes.object.isRequired, // To get course name
}
export default Header
