import React from "react"
import PropTypes from "prop-types"
import Style from "../style.module.scss"
import Image from "../../custom/Image"

import { FormControl } from "react-bootstrap"

import SearchIcon from "../../../images/svgs/search.svg"
import Loader from "../../custom/Loader"

/**
 * Component for question search
 * @param {func} props.handleSearchInput - function to handle search question
 * @param {boolean} props.loader - boolean value to show loader in text field
 * @returns {React.ReactElement} Question search box
 */

const Search = ({ handleSearchInput, loader }) => {
  return (
    <div className={`col-12 d-inline-flex ${Style.search_section} py-1 mb-2`}>
      <Image src={SearchIcon} alt={`search button`} className={`my-auto`} />
      <FormControl
        autoFocus
        className={`border-0 py-0 px-2 form-control shadow-none font-14 ${Style.search_question}`}
        placeholder={`Search questions or topics`}
        onChange={handleSearchInput}
        autoComplete="anyRandomString"
      />
      {loader && <Loader />}
    </div>
  )
}

Search.propTypes = {
  handleSearchInput: PropTypes.func.isRequired,
  loader: PropTypes.bool,
}

Search.defaultProps = {
  loader: false,
}

export default Search
