import React, { useEffect, useContext } from "react"

import { DragDropContext } from "react-beautiful-dnd"
import { ClassroomContext } from "../../../stores/classroom_store/ClassroomStore"
import { SHOW_ACTIVE_ACTIVITY, SHOW_TRIGGERED_RESOURCE, SET_ACTIVE_ACTIVITY_DATA } from "../../../stores/classroom_store/ClassroomActions"

import { putRequest, getRequest, postRequest } from "../../../services/rest_service"

import { TrainingSocketContext } from "../../../stores/socket/training_socket/trainingSocketProvider"
import { DataSocketContext } from "../../../stores/socket/data_socket/dataSocketProvider"

import { question_count_update } from "../../question-answer/ws-events"

import ModuleCard from "./ModuleCard"
import QuestionSidebar from "../../question-answer/Sidebar"

import { LessonboardContext } from "../../../stores/classroom_store/lessonboard_store/LessonboardStore"
import {
  SET_DRAGGED_ITEM,
  SET_TOPIC_DATA,
  SET_TOPIC_DATA_EMPTY,
  SET_TOPIC_DATA_ON_DROP,
  SET_MODULES_DATA,
} from "../../../stores/classroom_store/lessonboard_store/LessonboardActions"

import Style from "./style.module.scss"

const LessonBoard = () => {
  const [classroomState, dispatch] = useContext(ClassroomContext)
  const [lessonboardStore, lessonboardDispatch] = useContext(LessonboardContext)
  const [trainingWSready, trainingWSval, trainingWSsend] = useContext(TrainingSocketContext)
  const [dataWSready, dataWSval, dataWSsend] = useContext(DataSocketContext)

  useEffect(() => {
    if (!classroomState.activeSession) {
      lessonboardDispatch({ type: SET_TOPIC_DATA_EMPTY })
    }
  }, [classroomState.activeSession])

  useEffect(() => {
    try {
      if (trainingWSval) {
        if (trainingWSval?.event_type?.includes("question")) {
          question_count_update(trainingWSval, lessonboardStore.topics, lessonboardDispatch)
        }
        if (trainingWSval.event_type === "topic_feedback_updated") {
          let data = { ...lessonboardStore.topics["module_Done"] }
          let index = data.topics.findIndex((topic) => topic.id == trainingWSval.topic)
          if (data.topics[index]) {
            data.topics[index].feedbacks = trainingWSval.feedback
            lessonboardDispatch({ type: SET_TOPIC_DATA, payload: { key: "module_Done", value: { topics: data.topics, topicNext: data.topicNext } } })
          }
        }
        if (trainingWSval.event_type === "topic-moved") {
          const fromModuleIndex = lessonboardStore.modules.findIndex((module) => module.id == trainingWSval.from_stage)
          const toModuleIndex = lessonboardStore.modules.findIndex((module) => module.id == trainingWSval.to_stage)
          const fromModuleName = `module_${lessonboardStore.modules[fromModuleIndex]?.name?.split(" ")?.join("_")}`
          const toModuleName = `module_${lessonboardStore.modules[toModuleIndex]?.name?.split(" ")?.join("_")}`
          const initialTopicIndex = lessonboardStore.topics[fromModuleName]?.topics.findIndex((topic) => topic.id == trainingWSval.topic)

          const draggedItem = {
            destination: {
              droppableId: toModuleName,
              index: 0,
            },
            draggableId: `${trainingWSval?.topic}-topic_${initialTopicIndex}`,
            reason: "DROP",
            type: "topicDraggable",
            source: {
              droppableId: fromModuleName,
              index: initialTopicIndex,
            },
          }

          initialTopicIndex >= 0 && onDragEnd(draggedItem, false)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }, [trainingWSval])

  useEffect(() => {
    if (dataWSval && classroomState?.activeSession?.id) {
      //Add new topic
      if (dataWSval.fetch_type === "todo1") {
        fetchTopics("module_To_Do", lessonboardStore.modules[0].id, true)
      } else if (dataWSval.fetch_type === "todo") {
        fetchTopics("module_To_Do", lessonboardStore.modules[0].id, true)
      }
      //Edit topic name
      if (dataWSval.fetch_type === "sptopicedited") {
        let sessionId = parseInt(dataWSval?.value?.split("_")[0])
        if (sessionId === classroomState.activeSession.id) {
          let [topicId, topicName] = dataWSval.id.split("_$_")
          renameTopic(parseInt(topicId), topicName)
        }
      }
      //Delete topic
      if (dataWSval.fetch_type === `todo1__${classroomState.activeSession.id}`) {
        let todoIndex = lessonboardStore.topics["module_To_Do"].topics.findIndex((topic) => topic.id === parseInt(dataWSval.id))
        if (todoIndex !== -1) {
          fetchTopics("module_To_Do", lessonboardStore.modules[0].id, true)
        } else {
          let doingIndex = lessonboardStore.topics["module_Doing"].topics.findIndex((topic) => topic.id === parseInt(dataWSval.id))
          if (doingIndex !== -1) {
            fetchTopics("module_Doing", lessonboardStore.modules[1].id, true)
          }
        }
      }
    }
  }, [dataWSval])

  const renameTopic = (id, name) => {
    let topics = { ...lessonboardStore.topics }
    let key = "module_To_Do"
    let index = topics[key].topics.findIndex((topic) => topic.id === id)
    if (index === -1) {
      key = "module_Doing"
      index = topics[key].topics.findIndex((topic) => topic.id === id)
    }
    if (index !== -1) {
      topics[key].topics[index].name = name
      lessonboardDispatch({
        type: SET_TOPIC_DATA,
        payload: { key: key, value: { topics: topics[key].topics, topicNext: topics[key].topicNext } },
      })
    }
  }

  const reArrangeTopics = async (datum) => {
    const formData = new FormData()
    Object.entries(datum).forEach(([key, value]) => {
      if (value !== null && key !== "suggested_by") {
        if (key === "start_date" || key === "end_date") {
          formData.append(key, new Date(value).toISOString())
        } else if (key === "activity_workspace") {
          formData.append("activity_workspace", JSON.stringify(value))
        } else {
          formData.append(key, value)
        }
      }
    })
    const res = await putRequest(`/topics/${datum.id}/`, formData)
    if (res.success) {
      const session_datam = `${res.data?.session_data?.id}_${res.data?.session_data?.session_type}`
      triggerChanges("lbtopicreordred", datum.id, session_datam)
    }
  }

  const fetchTopics = async (key, moduleId, refetch = false) => {
    try {
      const modulesData = lessonboardStore.topics
      if (!modulesData[key] || refetch) {
        let response = await getRequest(`/lessonboard-stage/${moduleId}/`)
        if (response.success) {
          let topics = response.data.topics
          lessonboardDispatch({ type: SET_TOPIC_DATA, payload: { key, value: { topics, topicNext: response.data.next } } })
        } else {
          // TODO: add global error handling
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const triggerChanges = async (fetch_type, id = null, value = null) => {
    try {
      // Trigger websocket to trigger changes in student side
      let websocket_data = {
        event_type: "lesson_board",
        fetch_type,
        id,
        template_id: classroomState.template_id,
        value,
      }
      dataWSsend(JSON.stringify(websocket_data))
    } catch (error) {
      console.error(error)
    }
  }

  const handleDraggedItemSequence = async (currentItem, source, destination, to = `internal`) => {
    try {
      let formData = new FormData()
      formData.append("template", classroomState.template_id)
      formData.append("from_stage", source.id)
      formData.append("to_stage", destination.id)
      formData.append("topic", currentItem.id)

      // This value is used in student side to show feedback modal once topic is moved to done.
      let trigger_value = "enable"
      if (destination.name === "Done") {
        trigger_value = "disable"
      }

      let response = await postRequest(`/lessonboard-stage/move_topic/`, formData)
      if (response.success) {
        if (to === `external` && classroomState.activeSession) {
          triggerChanges(`topic_${currentItem.id}`, classroomState.activeSession.id, `${trigger_value}_$_${destination.id}`)
          if (destination?.name === "To Do") {
            reArrangeTopics(currentItem)
          }
        }
      } else {
        // TODO: Show error msg
      }
    } catch (error) {
      console.error(error)
    }
  }

  const reorder = (list, startIndex, destinationIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(destinationIndex, 0, removed)
    return result
  }

  function onDragEnd(draggedItem, updateTopicStatus = true) {
    try {
      const MODULE_MAP = { module_To_Do: 0, module_Doing: 1, module_Done: 2 }
      let { source, destination, type } = draggedItem
      lessonboardDispatch({ type: SET_DRAGGED_ITEM, payload: draggedItem })
      let topicsData = { ...lessonboardStore.topics }
      if (!destination) {
        return
      }
      if (type === "topicDraggable") {
        //Check if any on-going Activity is there, if so warn
        //If Link Resource is active, close it & proceed further
        if (classroomState.activeActivityData?.current_active_activity?.id && destination?.droppableId === "module_Done") {
          if (classroomState.activeActivityData?.current_active_activity?.resource?.resource_type === "link") {
            dispatch({ type: SHOW_TRIGGERED_RESOURCE, payload: false })
            dispatch({ type: SET_ACTIVE_ACTIVITY_DATA, payload: {} })
          } else {
            dispatch({ type: SHOW_ACTIVE_ACTIVITY, payload: true })
            return
          }
        }
        //if dropped inside same box
        if (source.droppableId === destination.droppableId) {
          if (destination?.droppableId === "module_To_Do") {
            if (source.index !== destination.index) {
              const currentData = topicsData[destination.droppableId].topics
              const items = reorder(currentData, source.index, destination.index)
              const currentTopic = items[destination.index]
              if (destination.index === 0) {
                currentTopic.sequence = (parseFloat(currentData[0].sequence) - 10).toFixed(20)
              } else if (destination.index === currentData.length - 1) {
                currentTopic.sequence = (parseFloat(currentData[currentData.length - 1].sequence) + 10).toFixed(20)
              } else {
                if (source.index > destination.index) {
                  currentTopic.sequence = (
                    (parseFloat(currentData[destination.index - 1].sequence) + parseFloat(currentData[destination.index].sequence)) /
                    2
                  ).toFixed(20)
                } else if (source.index < destination.index) {
                  currentTopic.sequence = (
                    (parseFloat(currentData[destination.index].sequence) + parseFloat(currentData[destination.index + 1].sequence)) /
                    2
                  ).toFixed(20)
                }
              }
              reArrangeTopics(currentTopic)
              lessonboardDispatch({
                type: SET_TOPIC_DATA,
                payload: { key: destination.droppableId, value: { topics: items, topicNext: topicsData[destination.droppableId].topicNext } },
              })
            }
          }
        } else {
          const sourceList = topicsData[source.droppableId].topics
          const destinationList = topicsData[destination.droppableId].topics
          const [draggedTopic] = sourceList.splice(source.index, 1)
          destinationList.splice(destination.index, 0, draggedTopic)

          if (destination.index === 0 && destinationList.length === 1) {
            draggedTopic.sequence = destination.index
          } else if (destination.index === 0) {
            if (draggedTopic) {
              draggedTopic.sequence = (parseFloat(destinationList[destination.index + 1].sequence) - 10).toFixed(20)
            }
          } else if (destination.index + 1 === destinationList.length) {
            draggedTopic.sequence = (parseFloat(destinationList[destination.index - 1].sequence) + 10).toFixed(20)
          } else {
            draggedTopic.sequence = (
              (parseFloat(destinationList[destination.index + 1].sequence) + parseFloat(destinationList[parseFloat(destination.index) - 1].sequence)) /
              2
            ).toFixed(20)
          }

          let payloadData = {
            sourceKey: source.droppableId,
            sourceValue: { topics: sourceList, topicNext: topicsData[source.droppableId].topicNext },
            destinationKey: destination.droppableId,
            destinationValue: { topics: destinationList, topicNext: topicsData[destination.droppableId].topicNext },
          }
          let from_stage = lessonboardStore.modules[MODULE_MAP[source.droppableId]]
          let to_stage = lessonboardStore.modules[MODULE_MAP[destination.droppableId]]
          updateTopicStatus && handleDraggedItemSequence(draggedTopic, from_stage, to_stage, "external")
          lessonboardDispatch({ type: SET_TOPIC_DATA_ON_DROP, payload: payloadData })
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div
        className={`flex-grow-1 flex-shrink-0 my-2 w-100 d-flex w-100`}
        style={{ height: "auto" }}
        onScroll={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        {lessonboardStore.modules.map((data, index) => (
          <ModuleCard key={`module_${index}`} fetchTopics={fetchTopics} data={data} />
        ))}
        <div className={`ml-2 ${Style.module_card}`} style={{ minWidth: "400px" }}>
          <div className={`p-2 ${Style.border_bottom}`}>Progress</div>
          <div className={`${Style.card_scrollable}`}>
            <QuestionSidebar />
          </div>
        </div>
      </div>
    </DragDropContext>
  )
}

export default LessonBoard
