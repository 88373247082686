import { postRequest } from "../../services/rest_service"

const closeTriggeredResourceAtStudent = async (trainingId, resourceId, sendSocket, callback = null, onErrorCallback = null, triggeredData = {}) => {
  let payload = {
    training: trainingId,
    activity_type: 1, //End
    resource: resourceId,
    trigger_count: 0,
    trigger_type: resourceId === null ? 2 : 1, //Resource
    triggered_data: triggeredData,
    owner: localStorage.getItem("student_id"),
  }

  let res = await postRequest(`/activity/`, payload)
  if (res.success) {
    //Case Mural link doesnt require socket action from frontend
    //if "resourceId" is null for Mural links
    //Close Student side Triggered Resource
    resourceId && sendSocket(JSON.stringify({ event_type: "close_triggered_resource", page: "none" }))
    callback && callback()
  } else {
    onErrorCallback && onErrorCallback(res.msg)
  }
}

export default closeTriggeredResourceAtStudent
