import React, { createContext, useReducer } from "react"
import TriggeredResourceReducer from "./TriggeredResourceReducer"

const initalState = {
  resourceData: null,
  studentsData: null,
  versionData: null,
}

const TriggeredResourceStore = ({ children }) => {
  const [state, dispatch] = useReducer(TriggeredResourceReducer, initalState)
  return <TriggeredResourceContext.Provider value={[state, dispatch]}>{children}</TriggeredResourceContext.Provider>
}

export const TriggeredResourceContext = createContext()
export default TriggeredResourceStore
