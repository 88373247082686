import React, { useContext } from "react"
import PropTypes from "prop-types"
import Image from "../../../custom/Image"
import Btn from "../../../custom/Button"
import { ClassroomContext } from "../../../../stores/classroom_store/ClassroomStore"
import {
  SET_ACTIVE_ACTIVITY_DATA,
  SHOW_ACTIVE_ACTIVITY,
  SHOW_TRIGGERED_RESOURCE,
  VIEW_MODERATION_RESOURCE,
} from "../../../../stores/classroom_store/ClassroomActions"
import { postRequest } from "../../../../services/rest_service"
import validateMuralUrl from "../../../mural/validateMuralUrl"
import muralIcon from "../../../../images/svgs/mural-icon.svg"
import linkIcon from "../../../../images/svgs/link.svg"
import Style from "../style.module.scss"

/**
 * Component for Workspace box
 * @param {object} props.topic - topic object
 * @param {string} props.title - module title
 * @returns {React.ReactElement} Workspace box in lesson board topic card
 */

const WorkspaceBox = ({ topic, title }) => {
  const [classroomState, dispatch] = useContext(ClassroomContext)

  let isMuralLink = validateMuralUrl(topic.activity_workspace?.link)
  let activeWorkSpace =
    classroomState.activeActivityData?.current_active_activity?.triggered_data?.link === topic.activity_workspace?.link &&
    topic.id === classroomState.activeActivityData?.current_active_activity?.triggered_data?.id &&
    classroomState.activeActivityData?.current_active_activity?.triggered_data?.model === "topic"

  const openNewTab = async (topic, activity_type, trigger_type) => {
    let payload = {
      training: classroomState.training_id,
      activity_type: activity_type,
      resource: null,
      trigger_count: 0,
      trigger_type: trigger_type,
      triggered_data: {
        id: topic.id,
        model: "topic",
        type: "mural",
        link: topic.activity_workspace?.link,
        canvas_link: topic.activity_workspace?.canvas_link,
      },
      owner: localStorage.getItem("student_id"),
    }
    let res = await postRequest(`/activity/`, payload)
    if (res.success) {
      if (activity_type === 2) {
        dispatch({ type: VIEW_MODERATION_RESOURCE, payload: {} })
        dispatch({
          type: SET_ACTIVE_ACTIVITY_DATA,
          payload: { current_active_activity: res.data.current_active_activity, active_breakout_session: res.data.active_breakout_session },
        })
        dispatch({ type: SHOW_TRIGGERED_RESOURCE, payload: true })
      } else {
        dispatch({ type: SET_ACTIVE_ACTIVITY_DATA, payload: {} })
      }
    } else {
      dispatch({
        type: SET_ACTIVE_ACTIVITY_DATA,
        payload: { current_active_activity: res.data.current_active_activity, active_breakout_session: res.data.active_breakout_session },
      })
      dispatch({ type: SHOW_ACTIVE_ACTIVITY, payload: true })
    }
  }

  return (
    <div className={`${Style.workspace_session_box} p-2 mt-2`}>
      <div className="d-flex align-items-center">
        <Image src={isMuralLink ? muralIcon : linkIcon} alt={`topic_workspace_link`} className={`my-auto`} />
        <p>Lesson WorkSpace</p>
      </div>
      {title === "Doing" ? (
        <div className={`d-inline-flex pt-2 w-100 align-items-center`} style={{ overflowX: "scroll" }}>
          {activeWorkSpace ? (
            <Btn onClick={(e) => openNewTab(topic, 1, 2)} className={`d-flex ml-0`}>
              Close
            </Btn>
          ) : (
            <Btn onClick={(e) => openNewTab(topic, 2, 2)} className={`d-flex ml-0`}>
              Open
            </Btn>
          )}
        </div>
      ) : null}
    </div>
  )
}

WorkspaceBox.propTypes = {
  topic: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
}

export default WorkspaceBox
