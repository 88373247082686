import React, { useContext, useRef, useState } from "react"
import PropTypes from "prop-types"
import Style from "../style.module.scss"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { QuestionContext } from "../../../stores/question_store/QuestionStore"
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"

/**
 * Component for question filters by session
 * @param {number} props.selectedSession - session id of selected session from filter
 * @param {func} props.handleSessionFilters - function to handle selection of session filter
 * @returns {React.ReactElement} Question filter by Session
 */

const QuestionFilters = ({ handleSessionFilters, selectedSession }) => {
  const [questionStore, questionDispatch] = useContext(QuestionContext)

  const [showScrollLeft, setShowScrollLeft] = useState(false)
  const [showScrollRight, setShowScrollRight] = useState(false)
  const sessionListRef = useRef()

  const sessionList = questionStore.all_sessions

  const displayScrollButtons = () => {
    let ele = sessionListRef.current
    if (ele) {
      if (ele.scrollLeft == 0) {
        setShowScrollLeft(false)
      } else {
        setShowScrollLeft(true)
      }
      if (ele.scrollLeft + ele.offsetWidth >= ele.scrollWidth) {
        setShowScrollRight(false)
      } else {
        setShowScrollRight(true)
      }
    }
  }

  const handleScroll = (type) => {
    let ele = sessionListRef.current
    if (ele !== null) {
      let flex_scroll_amount = ele.scrollWidth / ele.childElementCount
      let left_pos = ele.scrollLeft
      let new_left_pos = left_pos + 3 * flex_scroll_amount
      if (type === "left") {
        new_left_pos = left_pos - 3 * flex_scroll_amount
      }
      ele.scrollTo({
        top: 0,
        left: new_left_pos,
        behavior: "smooth",
      })
    }
  }

  const handleFilterClick = (id) => {
    if (selectedSession !== id) {
      handleSessionFilters(id)
    }
  }

  return (
    <div
      className={`w-100 mb-2 ${Style.horizontal_list}`}
      onMouseOver={() => {
        displayScrollButtons()
      }}
    >
      {showScrollRight ? (
        <div
          className={`px-0 pointer ${Style.scroll_btn}`}
          style={{ right: "0px" }}
          onClick={() => {
            handleScroll("right")
          }}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      ) : null}

      {showScrollLeft ? (
        <div
          className={`px-0 pointer ${Style.scroll_btn} `}
          style={{ left: "0px" }}
          onClick={() => {
            handleScroll("left")
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
      ) : null}

      <div className={`d-inline-flex mx-3 hide-scrollbar ${Style.filter_list}`} ref={sessionListRef}>
        {sessionList?.map((item, index) => {
          return (
            <div
              key={index.toString()}
              className={`mr-2 px-3 py-0 ${Style.name_container} ${selectedSession === item.id ? Style.active : ""}`}
              onClick={() => {
                handleFilterClick(item.id)
              }}
            >
              <p className={`${Style.name}`}>{item.name}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

QuestionFilters.propTypes = {
  handleSessionFilters: PropTypes.func.isRequired,
  selectedSession: PropTypes.number.isRequired,
}

export default QuestionFilters
