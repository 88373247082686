import React, { useState, useEffect, useRef, useContext } from "react"
import Loadable from "@loadable/component"
import { isBrowser } from "../../services/developer_mode"
import { Rnd } from "react-rnd"
import { SET_ACTIVE_ACTIVITY_DATA, SHOW_TRIGGERED_RESOURCE } from "../../stores/classroom_store/ClassroomActions"
import { ClassroomContext } from "../../stores/classroom_store/ClassroomStore"
import { TrainingSocketContext } from "../../stores/socket/training_socket/trainingSocketProvider"
import { GlobalContext } from "../../stores/global_store/GlobalStore"
import { SET_NOTIFICATION_DATA } from "../../stores/global_store/GlobalActions"
import closeTriggeredResourceAtStudent from "./closeTriggeredResource"
import validateMuralUrl from "../mural/validateMuralUrl"
import openURL from "../../services/open_url"
import Image from "../custom/Image"
import "./triggerResource.scss"

import openInNewTab from "../../images/svgs/open_in_new_tab.svg"
import fullScreenSvg from "../../images/svgs/fullscreen_svg.svg"
import minimizeSvg from "../../images/svgs/dark_minimize.svg"
import CancelIcon from "../../images/svgs/cancel-white-icon.svg"

import { MIN_WIDTH, MIN_HEIGHT, DEF_WIN_WIDTH, DEF_WIN_HEIGHT, FILE_TYPE_DESC } from "./constants.js"
import { useAssessmentMural } from "../../stores/assessment_mural_store/AssessmentMuralStore"
import { setShowMuralCanvas } from "../../stores/assessment_mural_store/AssessmentMuralActions"

const VideoControl = Loadable(() => import("../videocards/videocard-control"))
const TurientImageTrigger = Loadable(() => import("../turientImageResource"))
const BlogTrigger = Loadable(() => import("../blogs/triggeredBlog"))
const TurientLinkTrigger = Loadable(() => import("../links/triggeredLink"))
const DocumentTrigger = Loadable(() => import("../documentResource"))
const CaseStudyModeration = Loadable(() => import("../case-study/moderation"))
const PopQuiz = Loadable(() => import("../pop-quiz/popQuiz-results"))
const ClassRoomPoll = Loadable(() => import("../classroom_poll/classroomPoll"))
const CheckListModeration = Loadable(() => import("../checklist/moderation"))
const QuizModeration = Loadable(() => import("../quizzes/newmoderation/index"))
const MTFShareBoard = Loadable(() => import("../match-the-following/share_mtf_board"))
const MuralCanvas = Loadable(() => import("../mural-canvas"))

const TriggeredResource = () => {
  const [classroomStore, setClassroomStore] = useContext(ClassroomContext)
  const [isSocketReady, _, sendSocket] = useContext(TrainingSocketContext)
  const winWidth = useRef(DEF_WIN_WIDTH)
  const winHeight = useRef(DEF_WIN_HEIGHT)
  const [rndRect, setRndRect] = useState({ x: 100, y: 40, width: winWidth.current, height: winHeight.current })
  const [windowScreen, setWindowScreen] = useState("maximize")
  const [resourceHeader, setResourceHeader] = useState("")
  const [rndDragDisableFlag, setRndDragDisableFlag] = useState(false)
  const [globalStore, globalDispatch] = useContext(GlobalContext)
  const [state, muralDispatch] = useAssessmentMural()
  //const [fullScreen, setFullScreen] = useState(true)

  const [typeToShow, setTypeToShow] = useState("")
  const trainingID = classroomStore?.training_id

  useEffect(() => {
    if (typeToShow === "mural-link") {
      muralDispatch(setShowMuralCanvas(true))
    }
  }, [typeToShow])

  //Store the View Moderation resource/ current Active Activity Resource
  let resource = null,
    resourceType = ""
  if (classroomStore.viewModerationResource?.resource?.id) {
    resource = classroomStore.viewModerationResource?.resource
    resourceType = "VIEW_MODERATION"
  }
  if (classroomStore.activeActivityData?.current_active_activity?.resource?.id) {
    resource = classroomStore.activeActivityData?.current_active_activity?.resource
    resourceType = "ACTIVE_ACTIVITY"
  }

  //For Lesson Workspace Mural
  if (resource === null && classroomStore.activeActivityData?.current_active_activity?.triggered_data?.id) {
    let res = classroomStore.activeActivityData?.current_active_activity?.triggered_data
    resource = { id: null, resource_type: "link", resource_data: { ...res, name: "Lesson Workspace" }, triggered_data: { ...res } }
    resourceType = "LESSON_WORKSPACE"
  }

  useEffect(() => {
    if (resource) {
      prepareForRendering()
    }
  }, [classroomStore.viewModerationResource, classroomStore.activeActivityData?.current_active_activity?.resource])

  useEffect(() => {
    changeWindowScreen()
  }, [windowScreen])

  useEffect(() => {
    if (isBrowser()) {
      setWinWidthHeight()
      window.addEventListener("resize", setWinWidthHeight, false)
      return () => {
        window.removeEventListener("resize", setWinWidthHeight, false)
      }
    }
  }, [isBrowser()])

  const changeWindowScreen = () => {
    if (windowScreen === "maximize") {
      calcRectPositionFromResourceType()
      setRndDragDisableFlag(false)
    } else if (windowScreen === "minimize") {
      minimizeRnd()
      setRndDragDisableFlag(false)
    }
  }

  function minimizeRnd() {
    let parent = document?.body
    let x = parseInt(parent.clientWidth) - MIN_WIDTH - 95 //Left Side Nav Bar width (approx)
    let y = parseInt(parent.clientHeight) - MIN_HEIGHT - 10

    setRndRect({ x: x, y: y, width: MIN_WIDTH, height: MIN_HEIGHT })
  }

  const prepareForRendering = () => {
    let resourceType = resource.resource_type,
      resourceData = resource.resource_data,
      resourceTypeExact = resourceType,
      name = resourceData?.display_name ?? resourceData?.name ?? resourceTypeExact
    if (resourceType === "turientimage") {
      resourceTypeExact = FILE_TYPE_DESC[parseInt(resourceData.file_type)]
    }
    if (resourceType === "quiz") {
      if (resourceData.data_type === "1") {
        resourceTypeExact = "poll-quiz"
      } else if (resourceData.data_type === "2") {
        resourceTypeExact = "pop-quiz"
      }
    }
    if (resourceType === "link" && validateMuralUrl(resourceData.link)) {
      resourceTypeExact = "mural-link"
    }
    calcRectPositionFromResourceType()
    setTypeToShow(resourceTypeExact)
    setResourceHeader(name)
    if (resourceType === "casestudy") {
      setResourceHeader(`CaseStudy Students List - ${name}`)
    } else if (resourceType === "checklist") {
      setResourceHeader(`Checklist Students List - ${name}`)
    } else if (resourceType === "quiz") {
      setResourceHeader(`Quiz Students List - ${name}`)
    }
  }
  function setWinWidthHeight() {
    if (document && document?.body) {
      let width = document?.body?.clientWidth || DEF_WIN_WIDTH,
        height = document?.body?.clientHeight || DEF_WIN_HEIGHT
      winWidth.current = width
      winHeight.current = height
      calcRectPositionFromResourceType()
    }
  }

  const calcRectPositionFromResourceType = () => {
    if (resource && Object.keys(resource).length > 0) {
      let resourceType = resource.resource_type,
        resourceData = resource.resource_data,
        width = parseInt(winWidth.current) - 300,
        height = parseInt(winHeight.current) - 200,
        x,
        y
      //SVG Image, change the width,height
      if (resourceType === "turientimage" && resourceData.file_type === "0" && resourceData.avatar.search(/(\.svg)/i) >= 0) {
        width = 200
        height = 200
      }
      if (["video", "casestudy", "checklist", "quiz"].includes(resourceType)) {
        //increase the default width
        width += 200
        height += 50
      }

      x = (winWidth.current - width) / 2 - 30
      y = (winHeight.current - height) / 2 - 50
      y = y < 0 ? 10 : y

      setRndRect({ x, y, width, height })
    }
  }

  const closeRnd = (e = null) => {
    if (e === "close") {
      //after few milli secs, close the triggered window due to:
      //Warning: Cannot update a component (`ClassroomStore`) while rendering a different component (`TriggeredLink`).
      //To locate the bad setState() call inside `TriggeredLink`, follow the stack trace as described
      setTimeout(() => {
        setClassroomStore({ type: SHOW_TRIGGERED_RESOURCE, payload: false })
      }, 500)
      return
    }

    setClassroomStore({ type: SHOW_TRIGGERED_RESOURCE, payload: false })
    setClassroomStore({ type: SET_ACTIVE_ACTIVITY_DATA, payload: {} })
  }

  const closeTrainerAndStudentsRnd = (e) => {
    if (["checklist", "quiz", "casestudy"].includes(typeToShow)) {
      setClassroomStore({ type: SHOW_TRIGGERED_RESOURCE, payload: false })
    } else {
      let error = (errorMsg) => {
        if (!errorMsg) {
          errorMsg = "Error Occurred Closing"
        }
        globalDispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: errorMsg } })
      }

      let triggeredData = {}
      if (typeToShow === "mural-link") {
        triggeredData = {
          id: resource.topic,
          model: "topic",
          type: "mural",
          link: resource.resource_data.link,
          canvas_link: null,
        }
        resource = { ...resource, id: null, triggered_data: triggeredData }
      }
      closeTriggeredResourceAtStudent(
        classroomStore.training_id,
        resource?.id,
        sendSocket,
        closeRnd,
        error,
        resource?.id === null ? resource?.triggered_data : {} //for Mural (Lesson Workspace & Mural Links)
      )
    }
  }

  const preventDefaultBehaviour = (e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  const windowScreenCallback = (e, val) => {
    preventDefaultBehaviour(e)
    setRndDragDisableFlag(true)
    setWindowScreen(val)
  }

  const renderWindowRndIcons = (path, val, showText) => {
    return (
      <>
        <Image src={path} alt={showText} title={showText} className="mb-0 pointer mr-2 img_min_max" onClick={(e) => windowScreenCallback(e, val)} />
      </>
    )
  }

  const renderRndHeader = () => {
    return (
      <>
        <div className={`window_screen_icon position-absolute rnd_head_container mx-1`}>
          <div className={`d-flex w-100 align-items-baseline justify-content-between mx-auto ${windowScreen === "maximize" && `div_icons`}`}>
            <div className={`w-75`}>
              <h6 className={`${windowScreen === "minimize" ? "d-none" : ""} mb-0 text-white h6`}>{resourceHeader}</h6>
            </div>
            <div className={`d-flex align-items-center justify-content-center ${windowScreen === "minimize" && `div_icons mb-2`}`}>
              {resource.resource_type === "link" && (
                <Image
                  src={openInNewTab}
                  alt={"openInNewTab"}
                  title={"Open in new tab/window"}
                  className="mb-0 mr-2 pointer img_min_max"
                  onClick={(e) => {
                    setClassroomStore({ type: SHOW_TRIGGERED_RESOURCE, payload: false })
                    openURL(resource?.resource_data?.link)
                  }}
                />
              )}
              {windowScreen === "minimize" && renderWindowRndIcons(fullScreenSvg, "maximize", "Maximize")}
              {windowScreen === "maximize" && renderWindowRndIcons(minimizeSvg, "minimize", "Minimize")}
              <Image src={CancelIcon} alt={"Close"} title={"Close"} className="mb-0 pointer img_min_max" onClick={(e) => closeTrainerAndStudentsRnd(e)} />
            </div>
          </div>
        </div>
      </>
    )
  }

  const renderRndContent = () => {
    let width = ["checklist", "quiz", "casestudy"].includes(typeToShow) ? "1100px" : rndRect.width
    if (resource && Object.keys(resource).length > 0) {
      return (
        <div className={`p-1 w-100 rnd_content_header`}>
          {renderRndHeader()}
          <div style={{ minWidth: width }} className={`${windowScreen === "maximize" ? "rnd_content" : "rnd_content_min"}`}>
            {renderBasedOnResourceTypes()}
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const renderBlog = () => {
    return <BlogTrigger id={resource?.resource_data?.id ?? 0} />
  }

  const renderImage = () => {
    return <TurientImageTrigger resource={resource} />
  }

  const renderLink = () => {
    return <TurientLinkTrigger resource={resource} closeRnd={(val) => closeRnd(val)} />
  }

  const renderDocument = () => {
    return <DocumentTrigger resource={resource} typeofDocument={typeToShow} width={rndRect.width} height={rndRect.height} />
  }

  const renderVideo = () => {
    let resourceData = resource?.resource_data
    return (
      <VideoControl
        url={resourceData.url === "" ? resourceData.video_file : resourceData.url}
        channel={resourceData.channel}
        playing={false}
        controls={false}
        volume={0}
        muted={false}
        resourceID={resourceData.id}
        width={rndRect.width}
        height={rndRect.height}
      />
    )
  }

  const renderCaseStudyModeration = () => {
    return (
      <div className={`h-100 mb-2`}>
        <CaseStudyModeration resource={resource} />
      </div>
    )
  }

  const renderChecklistModeration = () => {
    return <CheckListModeration resource={resource} trainingID={trainingID} />
  }

  const renderBasedOnResourceTypes = () => {
    switch (typeToShow) {
      case "video":
        return renderVideo()
      case "blog":
        return renderBlog()
      case "image":
        return renderImage()
      case "link":
        return renderLink()
      case "PDF Document":
      case "Word Document":
      case "Excel Document":
      case "PPT Document":
      case "Google Word Document":
      case "Google PPT Document":
      case "Google Excel Document":
        return renderDocument()
      case "quiz":
        return (
          <div className={`h-100 mb-2`}>
            <QuizModeration resource={resource} />
          </div>
        )
      case "casestudy":
        return renderCaseStudyModeration()
      case "checklist":
        return renderChecklistModeration()
      default:
        return <div style={{ marginTop: 100 }}>Unknown resource</div>
    }
  }

  const renderTriggeredResource = () => {
    if (typeof resource === undefined) {
      return null
    }
    if (typeToShow === "poll-quiz") {
      return <ClassRoomPoll resource={resource} live_classroom={true} showPollResult={true} resourceType={resourceType} />
    } else if (typeToShow === "pop-quiz") {
      return <PopQuiz resource={resource} />
    } else if (typeToShow === "matchthefollowing") {
      return <MTFShareBoard classroomStore={classroomStore} resource={resource} training_id={classroomStore?.training_id} />
    } else if (typeToShow === "mural-link") {
      return (
        <>
          {resource?.resource_data?.link ? (
            <MuralCanvas
              copyNewWin={true}
              muralLink={resource?.resource_data?.link}
              showCanvasControl={true}
              onHideCallback={() => closeTrainerAndStudentsRnd()}
            />
          ) : null}
        </>
      )
    } else {
      return (
        <div className="top_left_absolute">
          <Rnd
            id={"educatorTriggeredResource"}
            minWidth={MIN_WIDTH}
            minHeight={MIN_HEIGHT}
            className={`rnd`}
            bounds={"body"}
            position={{ x: rndRect.x, y: rndRect.y }}
            size={{ width: `${rndRect.width}px`, height: `${rndRect.height}px` }}
            disableDragging={rndDragDisableFlag}
            onDragStop={(e, d) => setRndRect({ x: d.x, y: d.y, width: rndRect.width, height: rndRect.height })}
            onResizeStop={(e, direction, ref, delta, position) => {
              setRndRect({ x: position.x, y: position.y, width: parseInt(ref.style.width), height: parseInt(ref.style.height) })
            }}
          >
            {renderRndContent()}
          </Rnd>
        </div>
      )
    }
  }
  //Rendering starts from here
  return renderTriggeredResource()
}

export default TriggeredResource
